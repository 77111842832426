import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import Select from "react-select";
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat, threeColArray } from '../../../../../../Common/ChangeArrayFormat';
import { tableCustomStyles } from '../../../../../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../../../redux/api';
import { Decrypt_Id_Name } from '../../../../../../Common/Utility';
import ChangesModal from '../../../../../../Common/ChangesModal';
import { ErrorTooltip, VectimOffenderSpouceError } from '../../../../NameNibrsErrors';



const Relationship = (props) => {


  const { DecNameID, DecMasterNameID, victimID, DecIncID } = props

  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  const { get_NameVictim_Count, get_Name_Count, setChangesStatus, nameSingleData } = useContext(AgencyContext);
  const [clickedRow, setClickedRow] = useState(null);


  const [relationshipData, setRelationshipData] = useState([]);
  const [status, setStatus] = useState(false);
  const [RelationshipID, setRelationshipID] = useState('');
  const [editCount, setEditCount] = useState(0);
  const [relationShipDrp, setRelationShipDrp] = useState([]);
  const [name, setName] = useState([]);
  const [singleData, setSingleData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [loginPinID, setLoginPinID,] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [nameID, setNameID] = useState('');
  const [incidentID, setIncidentID] = useState();
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);
  const [selectedNameData, setSelectedNameData] = useState([]);
  const [relationTypeCode, setRelationTypeCode] = useState([]);


  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
    }
    get_NameVictim_Count(victimID);
    get_Name_Count(DecNameID);
  }, [localStoreData]);

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (DecNameID) { setNameID(DecNameID); setIncidentID(DecIncID); setValue({ ...value, 'CreatedByUserFK': loginPinID, }) }
    get_Name_Count(DecNameID)
  }, [DecNameID, loginPinID,]);

  const [value, setValue] = useState({
    'Code': 'VIC',
    'IncidentID': DecIncID,
    'VictimID': victimID,
    'NameID': DecNameID,
    'RelationshipTypeID': '',
    'VictimNameID': '',
    'OffenderNameID': '',
    'ModifiedByUserFK': '',
    'RelationshipID': '',

  });

  const [errors, setErrors] = useState({
    'RelationshipTypeIDErrors': '', 'VictimNameIDErrors': '',
  })

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.RelationshipTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['RelationshipTypeIDErrors']: RequiredFieldIncident(value.RelationshipTypeID) } })
    }
    if (RequiredFieldIncident(value.VictimNameID)) {
      setErrors(prevValues => { return { ...prevValues, ['VictimNameIDErrors']: RequiredFieldIncident(value.VictimNameID) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { RelationshipTypeIDErrors, VictimNameIDErrors } = errors

  useEffect(() => {
    if (RelationshipTypeIDErrors === 'true' && VictimNameIDErrors === 'true') {
      if (status && RelationshipID) update_Relationship()
      else save_Relationship()
    }
  }, [RelationshipTypeIDErrors, VictimNameIDErrors])

  useEffect(() => {
    get_Data_RelationShip_Drp(loginAgencyID); get_Data_Name_Drp(DecIncID, DecNameID); Get_Relationship_Data(DecNameID);
  }, [DecNameID, DecIncID, loginAgencyID])

  useEffect(() => {
    if (singleData[0]?.RelationshipID && RelationshipID) {
      setValue(pre => {
        return {
          ...pre,
          RelationshipTypeID: singleData[0]?.RelationshipTypeID,
          VictimNameID: singleData[0]?.VictimNameID,
          OffenderNameID: singleData[0]?.OffenderNameID,
          ModifiedByUserFK: loginPinID,
          RelationshipID: singleData[0]?.RelationshipID,
        }
      });
      singleData[0]?.VictimNameID && getSelectedNameSingleData(singleData[0]?.VictimNameID);
      singleData[0]?.VictimNameID && setRelationTypeCode(Get_RelationType_Code(singleData, relationShipDrp))
    } else {
      resetHooks()
    }
  }, [singleData, editCount])

  const get_Data_RelationShip_Drp = (loginAgencyID) => {
    const val = { 'AgencyID': loginAgencyID, }
    fetchPostData('VictimRelationshipType/GetDataDropDown_VictimRelationshipType', val).then((data) => {
      if (data) {
        // console.log("🚀 ~ fetchPostData ~ data:", data);
        // setRelationShipDrp(Comman_changeArrayFormat(data, 'VictimRelationshipTypeID', 'Description'))
        setRelationShipDrp(threeColArray(data, 'VictimRelationshipTypeID', 'Description', 'VictimRelationshipTypeCode'))
      } else {
        setRelationShipDrp([])
      }
    })
  }

  const get_Data_Name_Drp = (DecIncID, DecNameID) => {
    const val = { 'IncidentID': DecIncID, 'NameID': DecNameID, }
    fetchPostData('NameRelationship/GetDataDropDown_OffenderName', val).then((data) => {
      if (data) {
        setName(Comman_changeArrayFormat(data, 'NameID', 'Name'))
      } else {
        setName([])
      }
    })
  }

  const get_Single_Data = (RelationshipID) => {
    const val = { 'RelationshipID': RelationshipID, }
    fetchPostData('NameRelationship/GetSingleData_NameRelationship', val).then((data) => {
      if (data) {
        setSingleData(data)
      } else {
        setSingleData([])
      }
    })
  }

  const ChangeDropDown = (e, name) => {
    setStatesChangeStatus(true); setChangesStatus(true);
    if (e) {
      if (name === 'VictimNameID') {
        setValue({ ...value, [name]: e.value });
        getSelectedNameSingleData(e.value)
      } else if (name === 'RelationshipTypeID') {
        setRelationTypeCode(e?.id);
        // console.log("🚀 ~ ChangeDropDown ~ e:", e);
        setValue({ ...value, [name]: e.value });

      } else {
        setValue({ ...value, [name]: e.value });
      }
    } else {
      if (name === 'VictimNameID') {
        setValue({ ...value, [name]: null });
        setSelectedNameData([]);
      } else if (name === 'RelationshipTypeID') {
        setValue({ ...value, [name]: null });
        setRelationTypeCode('');
      } else {
        setValue({ ...value, [name]: null });
      }
    }
  }

  const getSelectedNameSingleData = (nameID, masterNameID) => {
    const val = { 'NameID': nameID, 'MasterNameID': masterNameID, 'IsMaster': false, }
    fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
      if (res) {
        setSelectedNameData(res[0]);
      } else { setSelectedNameData([]) }
    })
  }

  const save_Relationship = () => {
    const result = relationshipData?.find(item => {
      if (item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID) {
        return item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID
      } else return item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID
    });
    if (result) {
      toastifyError('VictimName And RelationshipType Already Exists');
      setErrors({ ...errors, ['RelationshipTypeIDErrors']: '' });
    } else {
      AddDeleteUpadate('NameRelationship/Insert_NameRelationship', value).then((data) => {
        if (data.success) {
          // toastifySuccess(data.Message);
          const parsedData = JSON.parse(data.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          Get_Relationship_Data(nameID); setStatus(false); resetHooks(); get_NameVictim_Count(victimID)
          get_Name_Count(DecNameID);
          setStatesChangeStatus(false); setChangesStatus(false)
        } else {
          toastifyError(data.Message)
        }
      })
    }
  }

  const update_Relationship = () => {
    const result = relationshipData?.find(item => {
      if (item?.RelationshipID != value['RelationshipID']) {
        if (item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID) {
          return item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID
        } else return item.VictimNameID === value.VictimNameID && item.RelationshipTypeID === value.RelationshipTypeID
      }
    });
    if (result) {
      toastifyError('Offender & Relationship Already Exists')
      setErrors({ ...errors, ['RelationshipTypeIDErrors']: '' })
    } else {
      AddDeleteUpadate('NameRelationship/Update_NameRelationship', value).then((data) => {
        if (data.success) {
          const parsedData = JSON.parse(data.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message);
          // toastifySuccess(data.Message);
          Get_Relationship_Data(nameID); setStatus(true);
          setStatesChangeStatus(false); setChangesStatus(false)
          get_Name_Count(DecNameID);
          resetHooks();
          setErrors({
            ...errors,
            'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '',
          });
        } else {
          toastifyError(data.Message)
        }
      })
    }
  }

  const resetHooks = () => {
    setValue({ ...value, RelationshipTypeID: '', VictimNameID: '', OffenderNameID: '', ModifiedByUserFK: '', RelationshipID: '', });
    setStatesChangeStatus(false); setChangesStatus(false); setRelationshipID(''); setStatus(false);
    setErrors({ ...errors, 'RelationshipTypeIDErrors': '', ' VictimNameIDErrors': '', });
    setSelectedNameData([]); setRelationTypeCode('');
  }

  const Get_Relationship_Data = (nameID) => {
    const val = { 'Code': 'VIC', 'NameID': nameID, }
    fetchPostData('NameRelationship/GetData_NameRelationship', val).then((res) => {
      if (res) {
        setRelationshipData(res)
      } else {
        setRelationshipData([]);
      }
    })
  }

  const columns = [
    // {
    //   name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
    //   cell: row =>
    //     <div style={{ position: 'absolute', top: 4, left: 20 }}>
    //       <Link to={'/nametab'} onClick={(e) => {
    //        setStatus(true); setRelationshipID(row.relationshipID); setEditCount(editCount + 1)
    //       }
    //       } className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#RelationshipModal">
    //         <i className="fa fa-edit"></i>
    //       </Link>

    //     </div>

    // },
    {
      name: 'Name',
      selector: (row) => row.Name,
      sortable: true
    },
    {
      name: 'Relationship',
      selector: (row) => row.RelationshipType_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: '10px' }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 10 }}>

          <span onClick={(e) => { setRelationshipID(row.RelationshipID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#RelationshipId">
            <i className="fa fa-trash"></i>
          </span>
        </div>

    }
  ];

  const DeleteRelationship = () => {
    const val = { 'RelationshipID': RelationshipID, 'DeletedByUserFK': loginPinID, }
    AddDeleteUpadate('NameRelationship/Delete_NameRelationship', val).then((res) => {
      if (res) {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        // toastifySuccess(res.Message); 
        setEditCount(editCount + 1)
        Get_Relationship_Data(nameID); setChangesStatus(false)
        get_NameVictim_Count(victimID)
        resetHooks(); setStatusFalse()
      } else { toastifyError("Somthing Wrong"); }
    })
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const NibrsErrorsStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "rgb(255 202 194)",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  const set_Edit_Value = (row) => {
    get_Single_Data(row.RelationshipID)
    setStatus(true);
    // setUpdateStatus(updateStatus + 1);
    setRelationshipID(row.RelationshipID);
    setStatesChangeStatus(false);
    setErrors({});
  }

  const setStatusFalse = (e) => {
    setClickedRow(null); resetHooks();
    setStatus(false)
    setStatesChangeStatus(false);
    setUpdateStatus(updateStatus + 1);
    setErrors({});
  }

  const getRelationNibrsError = (RelationTypeCode) => {
    if (RelationTypeCode == "SE") {
      return relationTypeCode == "SE" && selectedNameData?.AgeFrom < 13 ? <ErrorTooltip ErrorStr={VectimOffenderSpouceError} /> : null
    } else if (RelationTypeCode === "CH" || RelationTypeCode === "PA" || RelationTypeCode === "GP" || RelationTypeCode === "GC") {
      return selectedNameData?.AgeFrom > nameSingleData?.AgeFrom ? <ErrorTooltip ErrorStr={'the victim’s age must be greater than the offender’s age.'} /> : null
    } else {
      return <></>
    }
  }

  return (
    <>
      <div className="col-12 col-md-12 mt-2" >
        {/* <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center ">
          <p className="p-0 m-0 d-flex align-items-center">Relationship</p>
        </div> */}
        <div className="row">
          <div className="col-2 col-md-2 col-lg-2 mt-3">
            <label htmlFor="" className='label-name '>
              Offender{
                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                  // relationTypeCode == "SE" && selectedNameData?.AgeFrom < 13 ? <ErrorTooltip ErrorStr={VectimOffenderSpouceError} /> : null
                  getRelationNibrsError(relationTypeCode)
                  :
                  <></>
              }
              {errors.VictimNameIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.VictimNameIDErrors}</p>
              ) : null}</label>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='VictimNameID'
              // styles={colourStyles}
              styles={
                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                  relationTypeCode == "SE" && selectedNameData?.AgeFrom < 13 ? NibrsErrorsStyles : colourStyles
                  :
                  colourStyles
              }
              isClearable
              value={name?.filter((obj) => obj.value === value.VictimNameID)}
              options={name}
              onChange={(e) => { ChangeDropDown(e, 'VictimNameID'); }}
              placeholder="Select.."
            />
          </div>
          <div className="col-2 col-md-2 col-lg-2 mt-3">
            <Link to={'/ListManagement?page=Relationship%20Type&call=/Name-Home'} className='new-link'>
              Relationship {errors.RelationshipTypeIDErrors !== 'true' ? (
                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RelationshipTypeIDErrors}</p>
              ) : null}
            </Link>
          </div>
          <div className="col-4 col-md-4 col-lg-4  mt-2" >
            <Select
              name='RelationshipTypeID'
              styles={colourStyles}
              isClearable
              value={relationShipDrp?.filter((obj) => obj.value === value.RelationshipTypeID)}
              options={relationShipDrp}
              onChange={(e) => { ChangeDropDown(e, 'RelationshipTypeID'); }}
              placeholder="Select.."
            />
          </div>
          <div className="btn-box text-right col-12 col-md-12 col-lg-12 mt-2 mr-1 mb-2">
            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalse(); conditionalRowStyles(''); setUpdateStatus(updateStatus + 1); }}>New</button>
            {
              status ?
                <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                :
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
            }
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12  mb-3">
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={relationshipData}
                highlightOnHover
                customStyles={tableCustomStyles}
                onRowClicked={(row) => {
                  setClickedRow(row);
                  set_Edit_Value(row);
                }}
                fixedHeader
                persistTableHead
                fixedHeaderScrollHeight='100px'
                pagination
                paginationPerPage={'10'}
                paginationRowsPerPageOptions={[10, 15, 20, 50]}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <DeletePopUpModal func={DeleteRelationship} /> */}
      <div className="modal fade" data-backdrop="false" style={{ background: "rgba(0,0,0, 0.5)" }} id="RelationshipId" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="box text-center py-5">
              <h5 className="modal-title mt-2" id="exampleModalLabel">Are you sure you want to delete this record ?</h5>
              <div className="btn-box mt-3">
                <button type="button" onClick={DeleteRelationship} className="btn btn-sm text-white" style={{ background: "#ef233c" }} data-dismiss="modal">Delete</button>
                <button type="button" className="btn btn-sm btn-secondary ml-2 " data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangesModal func={check_Validation_Error} />

    </>
  )
}

export default Relationship

const Get_RelationType_Code = (data, dropDownData) => {
  console.log("🚀 ~ data:", data);

  const result = data?.map((sponsor) => (sponsor.RelationshipTypeID));
  const result2 = dropDownData?.map((sponsor) => {
    if (sponsor.value === result[0]) {
      return { value: result[0], label: sponsor.label, id: sponsor.id }
    }
  })
  const val = result2.filter(function (element) {
    return element !== undefined;
  });
  return val[0]?.id
};