import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Select from "react-select";
import DatePicker from "react-datepicker";
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import { Decrypt_Id_Name, DecryptedList, EncryptedList, base64ToString, getShowingMonthDateYear, getShowingWithOutTime, stringToBase64, tableCustomStyles } from '../../../../Common/Utility';
import { AddDeleteUpadate, ScreenPermision, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, threeColArray, threeColArrayWithCode } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import ChangesModal from '../../../../Common/ChangesModal';
import { ErrorStyle_NIBRS_09C, } from '../ErrorNibrs';
import DataTable from 'react-data-table-component';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import ListModal from '../../../Utility/ListManagementModel/ListModal';

const Home = ({ status, setStatus, setOffenceID, get_List, nibrsCode, setNibrsCode }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

  const { get_Offence_Count, updateCount, setUpdateCount, setChangesStatus, get_Offence_Data, changesStatus, get_Incident_Count, setIncidentStatus, setIncStatus, offenceFillterData, setcountoffaduit, } = useContext(AgencyContext);


  // Law Title
  const [lawTitleIdDrp, setLawTitleIdDrp] = useState([]);
  //NIBRS Code
  const [nibrsCodeDrp, setNibrsCodeDrp] = useState([]);
  // Offense Code/Name
  const [chargeCodeDrp, setChargeCodeDrp] = useState([]);

  const [offenderLeftSceneDrp, setOffenderLeftSceneDrp] = useState([]);
  const [categoryIdDrp, setCategoryIdDrp] = useState([]);
  const [locationIdDrp, setLocationIdDrp] = useState([]);
  const [editval, setEditval] = useState();
  const [crimeId, setCrimeId] = useState('');
  const [delCrimeId, setDelCrimeId] = useState('');
  const [mainIncidentID, setMainIncidentID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
  const [openPage, setOpenPage] = useState('');
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);
  const [primaryLocationCode, setPrimaryLocationCode] = useState('');

  const [value, setValue] = useState({
    'ChargeCodeID': '', 'NIBRSCodeId': null, 'OffenseCodeId': null, 'LawTitleId': null, 'OffenderLeftSceneId': null,
    'CategoryId': null, 'PrimaryLocationId': null, 'SecondaryLocationId': null, 'FTADate': '',
    'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
    'PropertyAbandoned': '', 'IsForceused': '', 'IsIncidentCode': false, 'AttemptComplete': "Completed",
    'CrimeID': '', 'IncidentID': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '',
  });

  const [errors, setErrors] = useState({
    'NibrsIdError': '',
    'ChargeCodeIDError': '',
    'PremisesEnteredError': '',
  });

  const useQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useQuery();
  var IncID = query?.get("IncId");
  var IncNo = query?.get("IncNo");
  var IncSta = query?.get("IncSta");
  var OffId = query?.get('OffId');
  var OffSta = query?.get('OffSta');
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));
  if (!OffId) OffId = 0;
  else OffId = parseInt(base64ToString(OffId));

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID); getScreenPermision(localStoreData?.AgencyID, localStoreData?.PINID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (IncID) {
      setValue({
        ...value,
        'IncidentID': IncID, 'CreatedByUserFK': '',
        'ChargeCodeID': '', 'NIBRSCodeId': null, 'OffenseCodeId': null, 'LawTitleId': null, 'OffenderLeftSceneId': null,
        'CategoryId': null, 'PrimaryLocationId': null, 'SecondaryLocationId': null, 'FTADate': '',
        'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
        'PropertyAbandoned': '', 'IsForceused': '', 'IsIncidentCode': false, 'AttemptComplete': "Completed",
        'CrimeID': '', 'ModifiedByUserFK': '',
      });
      get_Offence_Data(IncID); setMainIncidentID(IncID);
    }
  }, [IncID]);

  useEffect(() => {
    if (OffId && (OffSta === true || OffSta === 'true')) { setCrimeId(parseInt(OffId)); setOffenceID(parseInt(OffId)); GetSingleData(parseInt(OffId)); get_Offence_Count(OffId) }
  }, [OffId, OffSta]);

  const check_Validation_Error = (e) => {
    const NibrsIdErrorr = RequiredFieldIncident(value.NIBRSCodeId);
    const ChargeCodeIDErr = value?.NIBRSCodeId ? RequiredFieldIncident(value?.ChargeCodeID) : 'true';

    const PremisesEnteredErr = nibrsCode === '220' && (primaryLocationCode === '14' || primaryLocationCode === '19') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? RequiredFieldIncident(value?.PremisesEntered) : 'true';
    setErrors(pre => {
      return {
        ...pre,
        ['NibrsIdError']: NibrsIdErrorr || pre['NibrsIdError'],
        ['ChargeCodeIDError']: ChargeCodeIDErr || pre['ChargeCodeIDError'],
        ['PremisesEnteredError']: PremisesEnteredErr || pre['PremisesEnteredError']
      }
    })
  }

  // Check All Field Format is True Then Submit 
  const { ChargeCodeIDError, NibrsIdError, PremisesEnteredError } = errors

  useEffect(() => {
    if (ChargeCodeIDError === 'true' && NibrsIdError === 'true' && PremisesEnteredError === 'true') {
      if (OffId && (OffSta === true || OffSta === 'true')) { Update_Offence() }
      else { Add_Offense() }
    }
  }, [ChargeCodeIDError, NibrsIdError, PremisesEnteredError])

  const getScreenPermision = (LoginAgencyID, PinID) => {
    ScreenPermision("O036", LoginAgencyID, PinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }

  const GetSingleData = (crimeId) => {
    const val = { 'CrimeID': crimeId }
    fetchPostData('Crime/GetSingleData_Offense', val).then((res) => {
      if (res) {
        setEditval(res);
      } else { setEditval() }
    })
  }

  useEffect(() => {
    if (crimeId) {
      if (editval?.length > 0) {
        setcountoffaduit(true)
        // setcountoff(true)

        setValue({
          ...value,
          'CrimeID': editval[0]?.CrimeID,
          'NIBRSCodeId': editval[0]?.NIBRSCodeId,
          'ChargeCodeID': editval[0]?.ChargeCodeID,
          'LawTitleId': editval[0]?.LawTitleId,
          'OffenseCodeId': editval[0]?.OffenseCodeId,
          'PrimaryLocationId': editval[0]?.PrimaryLocationId,
          'SecondaryLocationId': editval[0]?.SecondaryLocationId,
          'OffenderLeftSceneId': editval[0]?.OffenderLeftSceneId,
          'CategoryId': editval[0]?.CategoryId,
          'clickedRow': editval[0]?.clickedRow,
          // text Box
          'Fine': editval[0]?.Fine ? editval[0]?.Fine : '', 'CourtCost': editval[0]?.CourtCost ? editval[0]?.CourtCost : '', 'FTAAmt': editval[0]?.FTAAmt ? editval[0]?.FTAAmt : "",
          'LitigationTax': editval[0]?.LitigationTax ? editval[0]?.LitigationTax : "", 'DamageProperty': editval[0]?.DamageProperty ? editval[0]?.DamageProperty : '',
          'OfRoomsInvolved': editval[0]?.OfRoomsInvolved ? editval[0]?.OfRoomsInvolved : '',
          'PremisesEntered': editval[0]?.PremisesEntered ? editval[0]?.PremisesEntered : '',
          // Checkbox
          'PropertyAbandoned': editval[0]?.PropertyAbandoned,
          'IsForceused': editval[0]?.IsForceused === 'N' || editval[0]?.IsForceused === null || editval[0]?.IsForceused === '' ? false : true,
          'IsIncidentCode': editval[0]?.IsIncidentCode,
          //Radio Button
          'AttemptComplete': editval[0]?.AttemptComplete,
          //Date picker
          'FTADate': editval[0]?.FTADate ? getShowingWithOutTime(editval[0]?.FTADate) : '',
          // other
          'ModifiedByUserFK': loginPinID, 'CreatedByUserFK': loginPinID,
        })
        NIBRSCodeDrpDwnVal(loginAgencyID, editval[0]?.LawTitleId);
        getChargeCodeIDDrp(editval[0]?.NIBRSCodeId, loginAgencyID, editval[0]?.LawTitleId);

        setNibrsCode(Get_Nibrs_Code(editval, nibrsCodeDrp))
        setPrimaryLocationCode(Get_PrimaryLocation_Code(editval, locationIdDrp))

        // console.log(Get_Nibrs_Code(editval, nibrsCodeDrp))
      }

    } else {
      // setcountoff(false)

      setValue({
        ...value,
        'ChargeCodeID': '',
        'NIBRSCodeId': null,
        'OffenseCodeId': null,
        'LawTitleId': null,
        'OffenderLeftSceneId': null,
        'CategoryId': null,
        'PrimaryLocationId': null,
        'SecondaryLocationId': null,
        // text Box
        'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
        // Checkbox
        'PropertyAbandoned': '',
        'IsForceused': '',
        //Radio Button
        'AttemptComplete': "Completed",
        //Date picker
        'FTADate': '',
      });
    }
  }, [editval,])

  useEffect(() => {
    nibrsCodeDrp?.filter(val => {
      if (val.value === value?.NIBRSCodeId) {
        setNibrsCode(val?.id)
      }
    });
  }, [value.NIBRSCodeId, nibrsCodeDrp]);

  useEffect(() => {
    locationIdDrp?.filter(val => {
      if (val.value === value?.PrimaryLocationId) {
        setPrimaryLocationCode(val?.id)
      }
    });
  }, [value.PrimaryLocationId, nibrsCodeDrp]);

  const resetEditVal = () => {
    if (crimeId) {
      if (editval?.length > 0) {
        setValue({
          ...value,
          'CrimeID': editval[0]?.CrimeID,
          'NIBRSCodeId': editval[0]?.NIBRSCodeId,
          'ChargeCodeID': editval[0]?.ChargeCodeID,
          'LawTitleId': editval[0]?.LawTitleId,
          'OffenseCodeId': editval[0]?.OffenseCodeId,
          'PrimaryLocationId': editval[0]?.PrimaryLocationId,
          'SecondaryLocationId': editval[0]?.SecondaryLocationId,
          'OffenderLeftSceneId': editval[0]?.OffenderLeftSceneId,
          'CategoryId': editval[0]?.CategoryId,
          'clickedRow': editval[0]?.clickedRow,
          // text Box
          'Fine': editval[0]?.Fine ? editval[0]?.Fine : '', 'CourtCost': editval[0]?.CourtCost ? editval[0]?.CourtCost : '', 'FTAAmt': editval[0]?.FTAAmt ? editval[0]?.FTAAmt : "",
          'LitigationTax': editval[0]?.LitigationTax ? editval[0]?.LitigationTax : "", 'DamageProperty': editval[0]?.DamageProperty ? editval[0]?.DamageProperty : '',
          'OfRoomsInvolved': editval[0]?.OfRoomsInvolved ? editval[0]?.OfRoomsInvolved : '',
          'PremisesEntered': editval[0]?.PremisesEntered ? editval[0]?.PremisesEntered : '',
          // Checkbox
          'PropertyAbandoned': editval[0]?.PropertyAbandoned,
          'IsForceused': editval[0]?.IsForceused === 'N' || editval[0]?.IsForceused === null || editval[0]?.IsForceused === '' ? false : true,
          'IsIncidentCode': editval[0]?.IsIncidentCode,
          //Radio Button
          'AttemptComplete': editval[0]?.AttemptComplete,
          //Date picker
          'FTADate': editval[0]?.FTADate ? getShowingWithOutTime(editval[0]?.FTADate) : '',
          // other
          'ModifiedByUserFK': loginPinID, 'CreatedByUserFK': loginPinID,
        })
        getChargeCodeIDDrp(editval[0]?.NIBRSCodeId, loginAgencyID, value?.LawTitleId);
        NIBRSCodeDrpDwnVal(loginAgencyID, editval[0]?.LawTitleId);
      }
    }
  }

  const Reset = () => {
    setValue({
      ...value,
      'ChargeCodeID': '', 'LawTitleId': '', 'OffenseCodeId': '', 'NIBRSCodeId': '', 'OffenderLeftSceneId': '',
      'CategoryId': '', 'PrimaryLocationId': '', 'SecondaryLocationId': '', 'Fine': '', 'CourtCost': '',
      'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
      'PropertyAbandoned': '', 'IsForceused': '', 'AttemptComplete': "Completed", 'FTADate': '', 'CrimeID': '',
    })
    setErrors({ ...errors, 'ChargeCodeIDError': '', 'NibrsIdError': '', 'PremisesEnteredError': '' });
    setCrimeId(''); setChargeCodeDrp([]); setChangesStatus(false); setStatesChangeStatus(false);


    setPrimaryLocationCode(''); setNibrsCode('');
  }

  const changeDropDown = (e, name) => {
    if (e) {
      setChangesStatus(true); setStatesChangeStatus(true)
      if (name === 'PrimaryLocationId') {
        setPrimaryLocationCode(e.id);
        setValue({ ...value, [name]: e.value });
        if (!e.value) {
          setValue(pre => ({ ...pre, ['SecondaryLocationId']: null }));
        }
      } else {
        setValue({ ...value, [name]: e.value });
      }
    } else if (e === null) {
      if (name === 'PrimaryLocationId') {
        setPrimaryLocationCode('');
        setValue({ ...value, [name]: null });
        setValue(pre => ({ ...pre, ['SecondaryLocationId']: null }));
        setErrors({ ...errors, 'PremisesEnteredError': '' });
      } else {
        setValue({ ...value, [name]: null });
      }
    } else {
      setValue({ ...value, [name]: null });
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'PropertyAbandoned' || e.target.name === 'IsForceused') {
      setChangesStatus(true); setStatesChangeStatus(true)
      setValue({
        ...value,
        [e.target.name]: e.target.checked
      })
    } else if (e.target.name === 'OfRoomsInvolved' || e.target.name === 'PremisesEntered') {
      const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
      setChangesStatus(true); setStatesChangeStatus(true)
      setValue({
        ...value,
        [e.target.name]: checkNumber
      });
    }
    else if (e.target.name === 'Fine' || e.target.name === 'LitigationTax' || e.target.name === 'FTAAmt' || e.target.name === 'CourtCost' || e.target.name === 'DamageProperty') {
      var ele = e.target.value.replace(/[^0-9\.]/g, "")
      if (ele.includes('.')) {
        if (ele.length === 16) {
          setChangesStatus(true); setStatesChangeStatus(true)
          setValue({ ...value, [e.target.name]: ele });
        } else {

          if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
            const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)

            if (!checkDot) {
              setChangesStatus(true); setStatesChangeStatus(true)
              setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
            }
          } else {
            setChangesStatus(true); setStatesChangeStatus(true)
            setValue({ ...value, [e.target.name]: ele })
          }
        }
      } else {
        if (ele.length === 16) {
          setChangesStatus(true); setStatesChangeStatus(true)
          setValue({
            ...value,
            [e.target.name]: ele
          });
        } else {
          setChangesStatus(true); setStatesChangeStatus(true)
          setValue({
            ...value,
            [e.target.name]: ele
          });
        }
      }
    } else {
      setChangesStatus(true); setStatesChangeStatus(true)
      setValue({
        ...value,
        [e.target.name]: e.target.value
      })
    }
  }

  useEffect(() => {
    if (openPage || loginAgencyID) {
      OffenderLeftSceneDrpDwnVal(loginAgencyID); CategoryDrpDwnVal(loginAgencyID); LocationIdDrpDwnVal(loginAgencyID);

      // lawtitle dpr
      LawTitleIdDrpDwnVal(loginAgencyID)
      // NIBRSCodeId
      NIBRSCodeDrpDwnVal(loginAgencyID, 0);
    }
  }, [loginAgencyID, openPage]);

  const getNibrsValue = (ChargeCodeID) => {
    const val = { 'ChargeCodeID': ChargeCodeID }
    fetchPostData('ChargeCodes/GetDataDropDown_NIBRS', val).then((data) => {
      if (data) {
        setValue(pre => { return { ...pre, ['NIBRSCodeId']: data[0].FBIID, } });
      }
    })
  }

  const LocationIdDrpDwnVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('LocationType/GetDataDropDown_LocationType', val).then((data) => {
      if (data) {
        setLocationIdDrp(threeColArray(data, 'LocationTypeID', 'Description', 'LocationTypeCode'))
      } else {
        setLocationIdDrp([]);
      }
    })
  }

  const CategoryDrpDwnVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('ChargeCategory/GetDataDropDown_ChargeCategory', val).then((data) => {
      if (data) {
        setCategoryIdDrp(Comman_changeArrayFormat(data, 'ChargeCategoryID', 'Description'))
      } else {
        setCategoryIdDrp([]);
      }
    })
  }

  const OffenderLeftSceneDrpDwnVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID, }
    fetchPostData('CrimeLeftScene/GetDataDropDown_CrimeLeftScene', val).then((data) => {
      if (data) {
        setOffenderLeftSceneDrp(Comman_changeArrayFormat(data, 'LeftSceneId', 'Description'))
      } else {
        setOffenderLeftSceneDrp([]);
      }
    })
  }

  const LawTitleIdDrpDwnVal = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID }
    fetchPostData('LawTitle/GetDataDropDown_LawTitle', val).then((data) => {
      if (data) {
        // console.log(data)
        setLawTitleIdDrp(Comman_changeArrayFormat(data, 'LawTitleID', 'Description'))
      } else {
        setLawTitleIdDrp([]);
      }
    })
  }

  const NIBRSCodeDrpDwnVal = (loginAgencyID, LawTitleID) => {
    const val = { AgencyID: loginAgencyID, 'LawTitleID': LawTitleID ? LawTitleID : null, }
    // const val = { AgencyID: loginAgencyID,  }
    fetchPostData('FBICodes/GetDataDropDown_FBICodes', val).then((data) => {
      if (data) {
        setNibrsCodeDrp(threeColArrayWithCode(data, 'FBIID', 'Description', 'FederalSpecificFBICode'))
      } else {
        setNibrsCodeDrp([]);
      }
    })
  }

  const getChargeCodeIDDrp = (NIBRSCodeId, loginAgencyID, LawTitleID) => {
    const val = { 'FBIID': NIBRSCodeId, 'LawTitleID': LawTitleID, 'AgencyID': loginAgencyID, }
    fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
      if (data) {
        setChargeCodeDrp(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
      } else {
        setChargeCodeDrp([]);
      }
    })
  }

  const onChangeDrpLawTitle = (e, name) => {
    setChangesStatus(true); setStatesChangeStatus(true);
    if (e) {
      // console.log(e)
      if (name === "LawTitleId") {
        setValue({ ...value, ['LawTitleId']: e.value, ['NIBRSCodeId']: null, ['ChargeCodeID']: null, });
        NIBRSCodeDrpDwnVal(loginAgencyID, e.value);
        //nibrs code
        setChargeCodeDrp([]);
        getChargeCodeIDDrp(value?.NIBRSCodeId, loginAgencyID, e.value);

      } else if (name === 'ChargeCodeID') {
        setValue({ ...value, ['ChargeCodeID']: e.value });
        // getChargeCodeIDDrp(e.value, loginAgencyID, value?.LawTitleId);
      } else {
        setValue({ ...value, [name]: e.value });
      }
    } else {
      setChangesStatus(true); setStatesChangeStatus(true);
      if (name === "LawTitleId") {
        setValue({ ...value, ['LawTitleId']: null, ['NIBRSCodeId']: '', ['ChargeCodeID']: null, });
        setNibrsCodeDrp([]); setChargeCodeDrp([]); setChargeCodeDrp([]);
        NIBRSCodeDrpDwnVal(loginAgencyID, 0);

        //nibrs code id
        getChargeCodeIDDrp(value?.NIBRSCodeId, loginAgencyID, 0)
      } else if (name === 'ChargeCodeID') {
        setValue({ ...value, ['ChargeCodeID']: null });
        // getChargeCodeIDDrp(0, loginAgencyID, value?.LawTitleId);
      } else {
        setValue({ ...value, [name]: null });
      }
    }
  }

  const onChangeNIBRSCode = (e, name) => {
    setChangesStatus(true); setStatesChangeStatus(true);
    if (e) {
      if (name === 'NIBRSCodeId') {
        if ((e.id === '09C' || e.id === '360') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan') {
          setNibrsCode(e.id);
          setValue({ ...value, ['NIBRSCodeId']: e.value, ['ChargeCodeID']: null, 'AttemptComplete': "Completed" });
          setChargeCodeDrp([]);
          getChargeCodeIDDrp(e.value, loginAgencyID, value?.LawTitleId);
        } else {
          setNibrsCode(e.id);
          setValue({ ...value, ['NIBRSCodeId']: e.value, ['ChargeCodeID']: null, });
          setChargeCodeDrp([]);
          getChargeCodeIDDrp(e.value, loginAgencyID, value?.LawTitleId);
        }
      } else {
        setValue({ ...value, [name]: e.value });
      }
    } else {
      setChangesStatus(true); setStatesChangeStatus(true);
      if (name === "NIBRSCodeId") {
        // console.log('adsfsfsd')
        setValue({ ...value, [name]: null, ['ChargeCodeID']: null, });
        setChargeCodeDrp([]);
        getChargeCodeIDDrp(0, loginAgencyID, value?.LawTitleId);
        // nibrs Errors States
        setNibrsCode(''); setPrimaryLocationCode('');
        setErrors({ ...errors, 'ChargeCodeIDError': '', 'PremisesEnteredError': '' });
      } else {
        setValue({ ...value, [name]: null });
      }
    }
  }

  useEffect(() => {
    if (status) { setValue({ ...value, ['FTADate']: '', }); }
  }, [])

  const Add_Offense = () => {
    if ((value?.SecondaryLocationId) && (value?.PrimaryLocationId == value?.SecondaryLocationId)) {
      toastifyError("The primary location and secondary location cannot be the same.");
    } else {
      const { ChargeCodeID, NIBRSCodeId, OffenseCodeId, LawTitleId, OffenderLeftSceneId,
        CategoryId, PrimaryLocationId, SecondaryLocationId, FTADate,
        Fine, CourtCost, FTAAmt, LitigationTax, DamageProperty, OfRoomsInvolved, PremisesEntered,
        PropertyAbandoned, IsForceused, IsIncidentCode, AttemptComplete,
        CrimeID, IncidentID, CreatedByUserFK, ModifiedByUserFK } = value
      const val = {
        'ChargeCodeID': ChargeCodeID, 'NIBRSCodeId': NIBRSCodeId, 'OffenseCodeId': OffenseCodeId, 'LawTitleId': LawTitleId, 'OffenderLeftSceneId': OffenderLeftSceneId,
        'CategoryId': CategoryId, 'PrimaryLocationId': PrimaryLocationId, 'SecondaryLocationId': SecondaryLocationId, 'FTADate': FTADate,
        'Fine': Fine, 'CourtCost': CourtCost, 'FTAAmt': FTAAmt, 'LitigationTax': LitigationTax, 'DamageProperty': DamageProperty, 'OfRoomsInvolved': OfRoomsInvolved, 'PremisesEntered': PremisesEntered,
        'PropertyAbandoned': PropertyAbandoned, 'IsForceused': IsForceused, 'IsIncidentCode': IsIncidentCode, 'AttemptComplete': AttemptComplete,
        'CrimeID': CrimeID, 'IncidentID': mainIncidentID, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': '',
      }

      AddDeleteUpadate('Crime/Insert_Offense', val).then((res) => {
        if (res.success) {
          Reset()
          if (res.CrimeID) {
            navigate(`/Off-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&OffId=${stringToBase64(res.CrimeID)}&OffSta=${true}`)
            get_Incident_Count(mainIncidentID);
            get_Offence_Data(mainIncidentID);
          }
          setChangesStatus(false); setStatesChangeStatus(false);
        }
        toastifySuccess(res.Message);

        //  setStatusFalse();
      })
    }
    setUpdateCount(updateCount + 1); setErrors({ ...errors, ['ChargeCodeIDError']: '', });
  }

  const Update_Offence = () => {
    if ((value?.SecondaryLocationId) && (value?.PrimaryLocationId == value?.SecondaryLocationId)) {
      toastifyError("The primary location and secondary location cannot be the same.");
      // return;
    } else {
      const { ChargeCodeID, NIBRSCodeId, OffenseCodeId, LawTitleId, OffenderLeftSceneId,
        CategoryId, PrimaryLocationId, SecondaryLocationId, FTADate,
        Fine, CourtCost, FTAAmt, LitigationTax, DamageProperty, OfRoomsInvolved, PremisesEntered,
        PropertyAbandoned, IsForceused, IsIncidentCode, AttemptComplete,
        CrimeID, IncidentID, CreatedByUserFK, ModifiedByUserFK } = value
      const val = {
        'ChargeCodeID': ChargeCodeID, 'NIBRSCodeId': NIBRSCodeId, 'OffenseCodeId': OffenseCodeId, 'LawTitleId': LawTitleId, 'OffenderLeftSceneId': OffenderLeftSceneId,
        'CategoryId': CategoryId, 'PrimaryLocationId': PrimaryLocationId, 'SecondaryLocationId': SecondaryLocationId, 'FTADate': FTADate,
        'Fine': Fine, 'CourtCost': CourtCost, 'FTAAmt': FTAAmt, 'LitigationTax': LitigationTax, 'DamageProperty': DamageProperty, 'OfRoomsInvolved': OfRoomsInvolved, 'PremisesEntered': PremisesEntered,
        'PropertyAbandoned': PropertyAbandoned, 'IsForceused': IsForceused, 'IsIncidentCode': IsIncidentCode, 'AttemptComplete': AttemptComplete,
        'CrimeID': CrimeID, 'IncidentID': mainIncidentID, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': loginPinID,
      }
      AddDeleteUpadate('Crime/Update_Offense', val).then((res) => {
        Reset()
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        setChangesStatus(false);
        setStatesChangeStatus(false);
        get_Offence_Data(mainIncidentID);
        get_List(OffId);
        setStatusFalse();

      })
    }
    setErrors({ ...errors, ['ChargeCodeIDError']: '', });
  }

  const setStatusFalse = (e) => {
    navigate(`/Off-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&OffId=${0}&OffSta=${false}`)
    setStatus(false); Reset();
  }

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const Cancel = () => {
    if (!changesStatus) {
      navigate('/Off-Home');
      // deleteStoreData({ 'OffenceID': '', 'OffenceStatus': '' });
      setIncidentStatus(true);
    }
  }

  const columns = [
    {
      name: 'NIBRS Code',
      selector: (row) => row.FBIID_Description,
      sortable: true
    },
    {
      name: 'Offense Code',
      selector: (row) => row.OffenseName_Description,
      sortable: true
    },
    {
      name: 'Law Title',
      selector: (row) => row.LawTitle_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 30 }}>Action</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 30 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <span onClick={(e) => setDelCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
              : <></>
              : <span onClick={(e) => setDelCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }
        </div>
    }
  ]

  const setEditVal = (row) => {
    if (row.CrimeID) {
      setStatesChangeStatus(false);
      navigate(`/Off-Home?IncId=${stringToBase64(IncID)}&IncNo=${IncNo}&IncSta=${IncSta}&OffId=${stringToBase64(row.CrimeID)}&OffSta=${true}`)
      setErrors({ ...errors, 'ChargeCodeIDError': '', 'NibrsIdError': '', });
      GetSingleData(row.CrimeID); get_Offence_Count(row.CrimeID);
      setCrimeId(row.CrimeID); setOffenceID(row?.CrimeID); setStatus(true);
      // Reset();
    }
    setUpdateCount(updateCount + 1); setIncStatus(true);
  }

  const DeleteOffence = () => {
    const val = { 'CrimeID': delCrimeId, 'DeletedByUserFK': loginPinID, }
    AddDeleteUpadate('Crime/Delete_Offense', val).then((res) => {
      const parsedData = JSON.parse(res.data);
      const message = parsedData.Table[0].Message;
      toastifySuccess(message);
      // toastifySuccess(res.Message);
      get_Incident_Count(mainIncidentID); get_Offence_Data(mainIncidentID);
      //  setStatus(false);
      // if (crimeId == delCrimeId) { Reset(); }
      // setDelCrimeId('');
      setStatusFalse();
      Reset();
    })
  }

  const conditionalRowStyles = [
    {
      when: row => row.CrimeID == crimeId,
      // when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),

  };

  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="col-12 bb">
        <div className="row">
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <span data-toggle="modal" onClick={() => { setOpenPage('Law Title') }} data-target="#ListModel" className='new-link px-0'>
              Law Title
            </span>
          </div>
          <div className="col-7 col-md-7 col-lg-2  mt-2">
            <Select
              name='LawTitleId'
              styles={customStylesWithOutColor}
              value={lawTitleIdDrp?.filter((obj) => obj.value === value?.LawTitleId)}
              options={lawTitleIdDrp}
              isClearable
              onChange={(e) => onChangeDrpLawTitle(e, 'LawTitleId')}
              placeholder="Select..."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-1">
            <label htmlFor="" className='new-label'>NIBRS Code</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3 mt-2">
            <Select
              name='NIBRSCodeId'
              // styles={
              //   process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
              //     nibrsCode != '09C' ? colourStyles : ErrorStyle_NIBRS_09C(nibrsCode)
              //     :
              //     colourStyles
              // }
              styles={colourStyles}
              value={nibrsCodeDrp?.filter((obj) => obj.value === value?.NIBRSCodeId)}
              options={nibrsCodeDrp}
              isClearable
              onChange={(e) => onChangeNIBRSCode(e, 'NIBRSCodeId')}
              placeholder="Select..."
            />
            {errors.NibrsIdError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NibrsIdError}</span>
            ) : null}
          </div>
          <div className="col-4 col-md-4 col-lg-1 mt-2 pt-1">
            <label htmlFor="" className='new-label'>Category</label>
          </div>
          <div className="col-7 col-md-7 col-lg-3  mt-2">
            <Select
              name='CategoryId'
              styles={customStylesWithOutColor}
              value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryId)}
              isClearable
              options={categoryIdDrp}
              onChange={(e) => changeDropDown(e, 'CategoryId')}
              placeholder="Select..."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <Link to={'/ListManagement?page=Charge%20Code&call=/Off-Home'} className='new-link'>
              Offense Code/Name
            </Link>
          </div>
          <div className="col-7 col-md-7 col-lg-6  mt-2">
            <Select
              name='ChargeCodeID'
              styles={value?.NIBRSCodeId ? colourStyles : customStylesWithOutColor}
              isDisabled={value?.NIBRSCodeId || value?.LawTitleId ? false : true}
              value={chargeCodeDrp?.filter((obj) => obj.value === value?.ChargeCodeID)}
              isClearable
              options={chargeCodeDrp}
              onChange={(e) => onChangeDrpLawTitle(e, 'ChargeCodeID')}
              placeholder="Select..."
            />
            {errors.ChargeCodeIDError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ChargeCodeIDError}</span>
            ) : null}
          </div>
          <div className="col-2 col-md-2 col-lg-1"></div>
          <div className="col-6 col-md-6 col-lg-1 mt-2 pt-1">
            <div className="form-check ">
              {
                value?.AttemptComplete === "Attempted" ?
                  <input
                    className="form-check-input mt-1"
                    type="radio" onChange={handleChange}
                    value="Attempted"
                    checked={value.AttemptComplete}
                    name="AttemptComplete" id="flexRadioDefault1"
                    disabled={
                      (nibrsCode === '09C' || nibrsCode === '360') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? true : false
                    }
                  />
                  :
                  <input
                    className="form-check-input mt-1"
                    type="radio" onChange={handleChange}
                    value="Attempted" name="AttemptComplete" id="flexRadioDefault1"
                    disabled={
                      (nibrsCode === '09C' || nibrsCode === '360') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? true : false
                    }
                  />
              }
              <label className="form-check-label " htmlFor="flexRadioDefault1" >
                Attempted
              </label>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-1 mt-2 pt-1 ml-5">
            <div className="form-check  ">
              {
                value?.AttemptComplete === "Completed" ?
                  <input className="form-check-input " type="radio" onChange={handleChange} value="Completed" checked={value.AttemptComplete} name="AttemptComplete" id="flexRadioDefault2" />
                  :
                  <input className="form-check-input" type="radio" onChange={handleChange} value="Completed" name="AttemptComplete" id="flexRadioDefault2" />
              }
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Completed
              </label>
            </div>
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <span data-toggle="modal" onClick={() => {
              setOpenPage('Location Type')
            }} data-target="#ListModel" className='new-link'>
              Primary Location
            </span>
          </div>
          <div className="col-7 col-md-7 col-lg-4 mt-2 ">
            <Select
              name='PrimaryLocationId'
              styles={customStylesWithOutColor}
              value={locationIdDrp?.filter((obj) => obj.value === value?.PrimaryLocationId)}
              isClearable
              options={locationIdDrp}
              onChange={(e) => changeDropDown(e, 'PrimaryLocationId')}
              placeholder="Select..."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <span data-toggle="modal" onClick={() => {
              setOpenPage('Location Type')
            }} data-target="#ListModel" className='new-link'>
              Secondary Location
            </span>
          </div>
          <div className="col-7 col-md-7 col-lg-4  mt-2">
            <Select
              name='SecondaryLocationId'
              styles={customStylesWithOutColor}
              value={locationIdDrp?.filter((obj) => obj.value === value?.SecondaryLocationId)}
              isClearable
              options={locationIdDrp}
              onChange={(e) => changeDropDown(e, 'SecondaryLocationId')}
              placeholder="Select..."
              isDisabled={!value?.PrimaryLocationId}
            />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <span data-toggle="modal" onClick={() => { setOpenPage('Crime Left  Scene') }} data-target="#ListModel" className='new-link px-0'>
              Offender Left Scene
            </span>
          </div>
          <div className="col-7 col-md-7 col-lg-2  mt-2">
            <Select
              name='OffenderLeftSceneId'
              styles={customStylesWithOutColor}
              value={offenderLeftSceneDrp?.filter((obj) => obj.value === value?.OffenderLeftSceneId)}
              isClearable
              options={offenderLeftSceneDrp}
              onChange={(e) => changeDropDown(e, 'OffenderLeftSceneId')}
              placeholder="Select..."
            />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <label htmlFor="" className='new-label'># Of Rooms Involved</label>
          </div>
          <div className="col-7 col-md-7 col-lg-2 mt-2 text-field">
            <input type="text" name='OfRoomsInvolved' value={value?.OfRoomsInvolved} onChange={handleChange} maxLength={4} required autoComplete='off' />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1 ">
            <label htmlFor="" className='new-label '>Damage Property</label>
          </div>
          <div className="col-7 col-md-7 col-lg-2 mt-2 text-field">
            <input type="text" name='DamageProperty' value={'$' + value?.DamageProperty} onChange={handleChange} maxLength={16} required autoComplete='off' />
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1">
            <label htmlFor="" className='new-label'>Premises Entered</label>
          </div>

          <div className="col-7 col-md-7 col-lg-2 mt-2 text-field">
            <input type="text"
              className={
                process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ?
                  nibrsCode === '220' && (primaryLocationCode === '14' || primaryLocationCode === '19') && process.env.REACT_APP_NIBRS_STATE === 'Rajasthan' ? 'requiredColor' : ''
                  :
                  ''
              }
              name='PremisesEntered' value={value?.PremisesEntered} onChange={handleChange} maxLength={2} required autoComplete='off' />
            {errors.PremisesEnteredError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PremisesEnteredError}</span>
            ) : null}
          </div>
          <div className="col-4 col-md-4 col-lg-2 mt-2 pt-1 ">
            <label htmlFor="" className='new-label '>Fine</label>
          </div>
          <div className="col-7 col-md-7 col-lg-2 mt-2 text-field">
            <input type="text" name='Fine' value={'$' + value?.Fine} onChange={handleChange} maxLength={16} required autoComplete='off' />
          </div>
          <div className="col-1"></div>
          <div className="col-6 col-md-6 col-lg-3 mt-3 mb-1">
            <div className="form-check ">
              <input className="form-check-input" type="checkbox" name='PropertyAbandoned' checked={value?.PropertyAbandoned} value={value?.PropertyAbandoned} onChange={handleChange} id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Property Abandoned
              </label>
            </div>
          </div>
        </div >
      </div >
      <div className="col-12 text-right mb-1 mt-1 field-button" >
        <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalse(); }}>New</button>
        {
          OffId && (OffSta === true || OffSta === 'true') ?
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.Changeok ?
                <button type="button" disabled={!statesChangeStatus} onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Update</button>
                :
                <>
                </>
              :
              <button type="button" disabled={!statesChangeStatus} onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Update</button>
            :
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.AddOK ?
                <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Save</button>
                :
                <>
                </>
              :
              <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Save</button>
        }
      </div>
      <div className="px-0 mt-2" >
        <DataTable
          showHeader={true}
          persistTableHead={true}
          dense
          columns={columns}
          data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? offenceFillterData : '' : offenceFillterData}
          highlightOnHover
          noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
          responsive
          customStyles={tableCustomStyles}
          onRowClicked={(row) => {
            setEditVal(row);
          }}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader
          fixedHeaderScrollHeight='170px'
          pagination
          paginationPerPage={'100'}
          paginationRowsPerPageOptions={[100, 150, 200, 500]}
          showPaginationBottom={100}
        />
      </div>
      <IdentifyFieldColor />
      <ChangesModal func={check_Validation_Error} setToReset={resetEditVal} />
      <DeletePopUpModal func={DeleteOffence} />
      <ListModal {...{ openPage, setOpenPage }} />
    </>
  )
}

export default Home

const Get_Nibrs_Code = (data, dropDownData) => {
  const result = data?.map((sponsor) => (sponsor.NIBRSCodeId));

  const result2 = dropDownData?.map((sponsor) => {
    if (sponsor.value === result[0]) {
      return { value: result[0], label: sponsor.label, id: sponsor.id }
    }
  })

  const val = result2.filter(function (element) {
    return element !== undefined;
  });

  return val[0]?.id
}

const Get_PrimaryLocation_Code = (data, dropDownData) => {
  // console.log(data)
  const result = data?.map((sponsor) => (sponsor.PrimaryLocationId));

  const result2 = dropDownData?.map((sponsor) => {
    if (sponsor.value === result[0]) {
      return { value: result[0], label: sponsor.label, id: sponsor.id }
    }
  })

  const val = result2.filter(function (element) {
    return element !== undefined;
  });

  return val[0]?.id
}