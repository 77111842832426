import { Decrypt_Id_Name } from "../../Components/Common/Utility";
import { ScreenPermision } from "../../Components/hooks/Api";
import moment from 'moment-timezone'

import {
    //----------------------------------------Drop_DownsDataArrays-----------------------------------
    Agency_OfficerDrp_Data, Screen_Permissions,

} from "../actionTypes"


// Get the current date and time in New York
const newYorkTime1 = moment().tz('America/New_York');
// Format the date and time as needed
const newYorkTimeZone = newYorkTime1.format('YYYY-MM-DD HH:mm:ss z');
// console.log(formattedTime)


// const now = new Date();
// // Get the current date and time in New York
// const newYorkTime = now.toLocaleString("en-US", { timeZone: "America/New_York" });

// const newYorkTimeZone = new Date(newYorkTime);


const initialState = {
    // All use
    uniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    agencyOfficerDrpData: [], effectiveScreenPermission: [], newYork_Time_Zone: newYorkTimeZone
}

const IncidentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Agency_OfficerDrp_Data:
            return {
                ...state,
                agencyOfficerDrpData: action.payload
            }
        case Screen_Permissions:
            return {
                ...state,
                effectiveScreenPermission: action.payload
            }
        default: return state
    }
}

export default IncidentReducer
