import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from "../../../Context/Agency/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faFile, faHouse, faLock, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { base64ToString, stringToBase64 } from "../../Common/Utility";

const DashboardSidebar = () => {
    let navigate = useNavigate();

    const { incidentRecentData, setUpdateCount, setIncStatus, updateCount } = useContext(AgencyContext)
    const [expand, setExpand] = useState()
    const [expandList, setExpandList] = useState()
    const [plusMinus, setPlusMinus] = useState(false)
    const [plusMinus1, setPlusMinus1] = useState(false)
    const [plusMinus2, setPlusMinus2] = useState(false)
    const [plusMinus3, setPlusMinus3] = useState(false)

    const callUtilityModules = (type, val) => {
        if (type === 'List') {
            setPlusMinus(!plusMinus);
            setPlusMinus1(!plusMinus1)
            setPlusMinus2(!plusMinus2)
            setPlusMinus3(!plusMinus3)
            setExpand(expand ? expand[0].id === val ? { id: val } : '' : { id: val })
        } else {
            setPlusMinus(!plusMinus);
            setExpandList(expandList === val ? '' : val);
        }
    }

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    return (
        <>
            <li className="">
                <Link to="/dashboard-page">
                    <FontAwesomeIcon icon={faHouse} className="react-icon pl-3" />
                    <span className="ml-2 pl-1">Dashboard</span>
                </Link>
            </li>
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus} onClick={() => callUtilityModules('Table', 'Master Table')}>
                    <FontAwesomeIcon icon={faLock} className="react-icon pl-3" />
                    <span className="ml-3">Utility</span></Link>
                <ul id="menu" role="menu" className={`${expandList === 'Master Table' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px' }}>
                    <li className="ml-2 p-0">
                        <Link to={`/ListManagement`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            {/* <i className="fa fa-home "></i> */}
                            <span className="m-0 p-0 pl-3">List Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/security-manager`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            <span className="m-0 p-0 pl-3">Security Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/Editable-Incident`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            <span className="m-0 p-0 pl-3">Editable  Incident </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/ListPermission`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            <span className="m-0 p-0 pl-3">List-Module Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/CounterTable`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            <span className="m-0 p-0 pl-3">Counter Table</span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/PreviousYearCounter`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-1" />
                            <span className="m-0 p-0 pl-3">Pervious Year Counter</span>
                        </Link>
                    </li>
                </ul>
            </li>
            {/* reports */}
            <li>
                <Link to="/Reports" className="has-arrow arrow-c" aria-expanded={plusMinus1} onClick={() => callUtilityModules('Table1', 'Master Table1')}>
                    {/* <i className="fa fa-file ml-3" aria-hidden="true" style={{ fontSize: '13px' }}></i> */}
                    <FontAwesomeIcon icon={faFile} className="react-icon pl-3" />
                    <span className="ml-3">Report</span>
                </Link>

            </li>
            {/* Applications */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus2} onClick={() => callUtilityModules('Table2', 'Master Table2')}>
                    <FontAwesomeIcon icon={faFile} className="react-icon pl-3" />
                    <span className="ml-3">Application</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table2'} className={`${expandList === 'Master Table2' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px' }}>
                    <li className="ml-2 p-0">
                        <Link to={`/PawnInformation`}>
                            <FontAwesomeIcon icon={faHouse} className="react-icon pl-3" />
                            <span className="m-0 p-0">Pawn Information</span>
                        </Link>
                    </li>
                </ul>
            </li>
            <li>
                <Link to="/Search" className="has-arrow arrow-c" aria-expanded={plusMinus3} onClick={() => callUtilityModules('Table3', 'Master Table3')}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="react-icon pl-3" />
                    <span className="ml-3">Search</span>
                </Link>
            </li>
            <div className="dropdown-divider"></div>
            <p>
                <Link to="#" >
                    <FontAwesomeIcon icon={faChevronRight} className="react-icon pl-3" />
                    <span className="ml-3">Recent</span>
                </Link>
                <ul className="recent">
                    {
                        incidentRecentData?.map((val) => (
                            <li key={val.IncidentID}>
                                <Link style={{ display: 'flex', flexDirection: 'column' }}
                                    to={`/Inc-Home?IncId=${stringToBase64(val?.IncidentID)}&IncNo=${val?.IncidentNumber}&IncSta=${true}`}
                                    onClick={() => {
                                        navigate(`/Inc-Home?IncId=${stringToBase64(val?.IncidentID)}&IncNo=${val?.IncidentNumber}&IncSta=${true}`);
                                        setIncStatus(true);
                                        setUpdateCount(updateCount + 1);
                                    }}>
                                    <span>Incident-{val.IncidentNumber}</span>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </p>
        </>
    )
}

export default DashboardSidebar