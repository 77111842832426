import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Decrypt_Id_Name, changeArrayFormat, filterPassedDateTime, filterPassedTime, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import NameListing from '../../../ShowAllList/NameListing';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/api';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/DropDownsData';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { useLocation } from 'react-router-dom';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import ChangesModal from '../../../../Common/ChangesModal';
import ListModal from '../../../Utility/ListManagementModel/ListModal';

const Warrant = (props) => {

    const { ListData, DecNameID, DecMasterNameID, DecIncID } = props

    const { get_Name_Count, localStoreArray, get_LocalStorage, setChangesStatus } = useContext(AgencyContext)

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const incReportedDate = useSelector((state) => state.Agency.incReportedDate);

    const [status, setStatus] = useState(false);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);
    const [nameWarrantID, setNameWarrantID] = useState();
    const [warrantDetailsData, setWarrantDetailsData] = useState([]);
    const [clickedRow, setClickedRow] = useState(null);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [updateStatus, setUpdateStatus] = useState(0)

    const [WarrentTypeDrp, setWarrentTypeDrp] = useState([])
    const [warrantStatus, setWarrantStatus] = useState([])
    const [agencyData, setAgencyData] = useState([])
    const [warrantID, setWarrantID] = useState([])
    const [warrentTypeData, setWarrentTypeData] = useState([])
    const [modal, setModal] = useState(false);
    const [DateTimeIssued, setDateTimeIssued] = useState(new Date());
    const [DateExpired, setDateExpired] = useState(false);
    const [editval, setEditval] = useState();
    const [openPage, setOpenPage] = useState('');


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let MstPage = query?.get('page');

    const [value, setValue] = useState({
        'WarrantNumber': '', 'NameID': '', 'WarrantTypeID': '', 'WarrantStatusID': '',
        'AssignedOfficerID': '', 'MasterNameID': '', 'IssuingAgencyID': '', 'DateTimeIssued': '', 'DateExpired': '',
        'CreatedByUserFK': '',
        'IsMaster': MstPage === "MST-Name-Dash" ? true : false,

    });

    const [errors, setErrors] = useState({
        'WarrantTypeIDErrors': '', 'WarrantNumberErrors': '', 'DateTimeIssuedErrors': '', 'DateExpiredErrors': '', 'IssuingAgencyIDErrors': '',
    })


    const reset = () => {
        setValue({
            ...value,
            'WarrantNumber': '', 'WarrantTypeID': '', 'WarrantStatusID': '', 'AssignedOfficerID': '',
            'IssuingAgencyID': '', 'DateTimeIssued': '', 'DateExpired': '',
        }); setErrors({ ...errors, 'WarrantTypeIDErrors': '', 'WarrantNumberErrors': '', 'DateTimeIssuedErrors': '', 'DateExpiredErrors': '', 'IssuingAgencyIDErrors': '', }); setDateTimeIssued(''); setDateExpired('')
    }

    const GetSingleData = (warrantID) => {
        const val = { WarrantID: warrantID, }
        fetchPostData('NameWarrant/GetSingleData_NameWarrant', val)
            .then((res) => {
                if (res) setEditval(res)
                else setEditval()
            }
            )
    }

    useEffect(() => {
        if (status) {

            setValue({
                ...value,
                'WarrantID': warrantID,
                'DateExpired': editval[0]?.DateExpired ? getShowingDateText(editval[0]?.DateExpired) : null,
                'DateTimeIssued': editval[0]?.DateTimeIssued ? getShowingDateText(editval[0]?.DateTimeIssued) : null,
                'WarrantNumber': editval[0]?.WarrantNumber, 'WarrantTypeID': editval[0]?.WarrantTypeID, 'WarrantStatusID': editval[0]?.WarrantStatusID,
                'AssignedOfficerID': editval[0]?.AssignedOfficerID, 'IssuingAgencyID': editval[0]?.IssuingAgencyID,
                'ModifiedByUserFK': loginPinID,
            });
            setDateExpired(editval[0]?.DateExpired ? new Date(editval[0]?.DateExpired) : null);
            setDateTimeIssued(editval[0]?.DateTimeIssued ? new Date(editval[0]?.DateTimeIssued) : null);
        } else {
            setValue({
                ...value,
                'WarrantNumber': '', 'WarrantTypeID': '', 'WarrantStatusID': '',
                'AssignedOfficerID': '', 'MasterNameID': '', 'IssuingAgencyID': '', 'DateTimeIssued': '', 'DateExpired': '',
                'ModifiedByUserFK': '',
            })
        }
    }, [editval])


    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            // setValue({ ...value, 'IncidentID': DecIncID, 'CreatedByUserFK': loginPinID, 'AgencyID': loginAgencyID });
            // get_Data_Arrest(DecEIncID, MstPage === "MST-Arrest-Dash" ? true : false); get_Arresting_DropDown(loginAgencyID, loginPinID);
            dispatch(get_AgencyOfficer_Data(localStoreData?.AgencyID, DecIncID))
        }
    }, [loginAgencyID]);

    useEffect(() => {
        if (DecNameID || DecMasterNameID) {
            setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MasterNameID': DecMasterNameID, 'NameID': DecNameID } });
            get_WarrentType_Data(DecNameID, DecMasterNameID);
        }
    }, [DecNameID, DecMasterNameID, loginPinID]);


    const get_WarrentType_Data = (DecNameID, DecMasterNameID) => {
        const val = { NameID: DecNameID, MasterNameID: DecMasterNameID, }
        const val2 = { MasterNameID: DecMasterNameID, NameID: 0, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false, }
        fetchPostData('NameWarrant/GetData_NameWarrant', MstPage ? val2 : val).then((res) => {
            if (res) {
                setWarrentTypeData(res)
            } else {
                setWarrentTypeData([]);
            }
        })
    }

    const check_Validation_Error = (e) => {
        const WarrantTypeIDErrors = RequiredFieldIncident(value.WarrantTypeID);
        const WarrantNumberErrors = RequiredFieldIncident(value.WarrantNumber);
        const DateTimeIssuedErrors = RequiredFieldIncident(value.DateTimeIssued);
        const DateExpiredErrors = RequiredFieldIncident(value.DateExpired);
        const IssuingAgencyIDErrors = RequiredFieldIncident(value.IssuingAgencyID);

        setErrors(pre => {
            return {
                ...pre,
                ['WarrantTypeIDErrors']: WarrantTypeIDErrors || pre['WarrantTypeIDErrors'],
                ['WarrantNumberErrors']: WarrantNumberErrors || pre['WarrantNumberErrors'],
                ['DateTimeIssuedErrors']: DateTimeIssuedErrors || pre['DateTimeIssuedErrors'],
                ['DateExpiredErrors']: DateExpiredErrors || pre['DateExpiredErrors'],
                ['IssuingAgencyIDErrors']: IssuingAgencyIDErrors || pre['IssuingAgencyIDErrors'],

            }
        });
    }
    const { WarrantTypeIDErrors, IssuingAgencyIDErrors, DateExpiredErrors, DateTimeIssuedErrors, WarrantNumberErrors } = errors

    useEffect(() => {
        if (WarrantTypeIDErrors === 'true' && IssuingAgencyIDErrors === 'true' && DateExpiredErrors === 'true' && DateTimeIssuedErrors === 'true' && WarrantNumberErrors === 'true') {
            if (warrantID && status) { update_Activity() }
            else { Add_Type() }
        }
    }, [WarrantTypeIDErrors, IssuingAgencyIDErrors, DateExpiredErrors, DateTimeIssuedErrors, WarrantNumberErrors])

    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true); setChangesStatus(true)
        if (e) {
            setValue({ ...value, [name]: e.value })
        } else {
            setValue({ ...value, [name]: null })
        }
    }

    const handleChange = (e) => {
        setStatesChangeStatus(true); setChangesStatus(true);
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (openPage || loginAgencyID || loginPinID) {
            GetWarrentType(loginAgencyID); GetwarrantStatus(loginAgencyID); getAgency(loginAgencyID, loginPinID,)
        }
    }, [loginAgencyID, loginPinID, openPage])


    const GetWarrentType = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('WarrantType/GetDataDropDown_WarrantType', val).then((data) => {
            if (data) {
                setWarrentTypeDrp(Comman_changeArrayFormat(data, 'WarrantTypeID', 'Description'))
            } else {
                setWarrentTypeDrp([]);
            }
        })
    }

    const GetwarrantStatus = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('WarrantStatus/GetDataDropDown_WarrantStatus', val).then((data) => {
            if (data) {
                setWarrantStatus(Comman_changeArrayFormat(data, 'WarrantStatusID', 'Description'))
            } else {
                setWarrantStatus([]);
            }
        })
    }

    const getAgency = async (loginAgencyID, loginPinID) => {
        const value = { AgencyID: loginAgencyID, PINID: loginPinID, }
        fetchPostData("Agency/GetData_Agency", value).then((data) => {
            if (data) {
                setAgencyData(Comman_changeArrayFormat(data, 'AgencyID', 'Agency_Name'))
            } else {
                setAgencyData();
            }
        })
    }

    const columns = [
        {
            name: 'Warrant Number',
            selector: (row) => row.WarrantNumber,
            sortable: true
        },
        {
            name: 'Warrant Type',
            selector: (row) => row.WarrantType_Description,
            sortable: true
        },

        {
            name: 'Assigned Officer',
            selector: (row) => row.AssignedOfficer,
            sortable: true
        },
        {
            name: 'Warrant Status',
            selector: (row) => row.WarrantStatus,
            sortable: true
        },
        {
            name: 'Issued Date/Time',
            selector: (row) => row.DateTimeIssued ? getShowingDateText(row.DateTimeIssued) : " ",
            sortable: true
        },

        {
            name: 'Expired Date/Time',
            selector: (row) => row.DateExpired ? getShowingDateText(row.DateExpired) : " ",
            sortable: true
        },


        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>
                    {
                        effectiveScreenPermission ?
                            effectiveScreenPermission[0]?.DeleteOK ?
                                <span onClick={() => { setWarrantID(row.WarrantID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                    <i className="fa fa-trash"></i>
                                </span>
                                : <></>
                            : <span onClick={() => { setWarrantID(row.WarrantID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </span>
                    }

                </div>

        }
    ]

    const setStatusFalse = (e) => {
        reset(); setStatesChangeStatus(false); setChangesStatus(false); setStatus(false); reset();
        setUpdateStatus(updateStatus + 1); setClickedRow(null);
    }

    const set_Edit_Value = (row) => {
        reset(); setStatus(true);
        setUpdateStatus(updateStatus + 1);
        setWarrantID(row.WarrantID);
        GetSingleData(row.WarrantID); setStatesChangeStatus(false); setChangesStatus(false);
    }

    const Add_Type = () => {
        const result = warrentTypeData?.find(item => {
            if (item.WarrantNumber === value.WarrantNumber) {
                return item.WarrantNumber === value.WarrantNumber
            } else return item.WarrantNumber === value.WarrantNumber
        });
        const result1 = warrentTypeData?.find(item => {
            if (item.WarrantTypeID === value.WarrantTypeID) {
                return item.WarrantTypeID === value.WarrantTypeID
            } else return item.WarrantTypeID === value.WarrantTypeID
        }
        );
        if (result || result1) {
            if (result) {
                toastifyError('WarrantNumber Already Exists')
                setErrors({ ...errors, ['WarrantTypeIDErrors']: '' })
            }
            if (result1) {
                toastifyError('WarrantType Already Exists')
                setErrors({ ...errors, ['WarrantTypeIDErrors']: '' })
            }
        } else {
            AddDeleteUpadate('NameWarrant/Insert_NameWarrant', value).then((res) => {
                get_WarrentType_Data(DecNameID, DecMasterNameID);
                setModal(false); setStatesChangeStatus(false);
                const parseData = JSON.parse(res.data);
                get_Name_Count(DecNameID, DecMasterNameID, MstPage === "MST-Name-Dash" ? true : false);
                toastifySuccess(parseData?.Table[0].Message);
                reset(); setErrors({ ...errors, 'WarrantTypeIDErrors': '', })
            })
        }
    }

    const update_Activity = () => {
        const result = warrentTypeData?.find(item => {
            if (item.WarrantID != value.WarrantID) {
                if (item.WarrantNumber) {
                    if (item.WarrantNumber === value.WarrantNumber) {
                        return item.WarrantNumber === value.WarrantNumber
                    } else return item.WarrantNumber === value.WarrantNumber
                }
            }
        });
        const result1 = warrentTypeData?.find(item => {
            if (item.WarrantID != value.WarrantID) {
                if (item.WarrantTypeID) {
                    if (item.WarrantTypeID === value.WarrantTypeID) {
                        return item.WarrantTypeID === value.WarrantTypeID
                    } else return item.WarrantTypeID === value.Description
                }
            }
        }
        );
        if (result || result1) {
            if (result) {
                toastifyError('WarrantNumber Already Exists')
                setErrors({ ...errors, ['WarrantTypeIDErrors']: '' })
            }
            if (result1) {
                toastifyError('WarrantType Already Exists')
                setErrors({ ...errors, ['WarrantTypeIDErrors']: '' })
            }
        } else {
            AddDeleteUpadate('NameWarrant/Update_NameWarrant', value).then((res) => {
                const parseData = JSON.parse(res.data);
                toastifySuccess(parseData?.Table[0].Message);
                get_Name_Count(DecNameID, DecMasterNameID, MstPage === "MST-Name-Dash" ? true : false);
                setChangesStatus(false); setModal(false); setStatesChangeStatus(false);
                get_WarrentType_Data(DecNameID, DecMasterNameID);
                setErrors({ ...errors, 'WarrantTypeIDErrors': '', })
                reset(); setStatus(false);
            })
        }
    }

    const DeleteNameAliases = () => {
        const val = { 'WarrantID': warrantID, 'DeletedByUserFK': loginPinID, }
        AddDeleteUpadate('NameWarrant/Delete_NameWarrant', val).then((res) => {
            if (res) {
                const parseData = JSON.parse(res.data);
                toastifySuccess(parseData?.Table[0].Message); setChangesStatus(false);
                get_Name_Count(DecNameID, DecMasterNameID, MstPage === "MST-Name-Dash" ? true : false);
                get_WarrentType_Data(DecNameID, DecMasterNameID); setStatus(false); reset()
            } else console.log("Somthing Wrong");
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: { backgroundColor: '#001f3fbd', color: 'white', cursor: 'pointer', },
        },
    ];

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20, minHeight: 35, fontSize: 14, margintop: 2, boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base, height: 20, minHeight: 33, fontSize: 14, margintop: 2, boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };

    const NameDateExpired = ListData[0]?.DateOfBirth


    return (
        <>
            <NameListing  {...{ ListData }} />
            <div className="col-md-12 mt-1">
                <div className="row">
                    <div className="col-3 col-md-3 col-lg-1 mt-2">
                        <label htmlFor="" className='label-name '>Warrant No.{errors.WarrantNumberErrors !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.WarrantNumberErrors}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                        <input type="text" className='requiredColor' value={value?.WarrantNumber} onChange={handleChange} name='WarrantNumber' required />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        {/* <label htmlFor="" className='label-name '>Warrant Type{errors.WarrantTypeIDErrors !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.WarrantTypeIDErrors}</p>
                        ) : null}</label> */}
                        <span data-toggle="modal" onClick={() => {
                            setOpenPage('Warrant Type')
                        }} data-target="#ListModel" className='new-link'>
                            Warrant Type{errors.WarrantTypeIDErrors !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.WarrantTypeIDErrors}</p>
                            ) : null}
                        </span>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 mt-1" >
                        <Select
                            name='WarrantTypeID'
                            styles={colourStyles}
                            value={WarrentTypeDrp?.filter((obj) => obj.value === value?.WarrantTypeID)}
                            isClearable
                            options={WarrentTypeDrp}
                            onChange={(e) => ChangeDropDown(e, 'WarrantTypeID')}
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        <label htmlFor="" className='label-name '>Assigned Officer</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-1" >
                        <Select
                            name='AssignedOfficerID'
                            styles={customStylesWithOutColor}
                            value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.AssignedOfficerID)}
                            isClearable
                            options={agencyOfficerDrpData}
                            onChange={(e) => ChangeDropDown(e, 'AssignedOfficerID')}
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2">
                        {/* <label htmlFor="" className='label-name '>Warrant&nbsp;Status</label> */}
                        <span data-toggle="modal" onClick={() => {
                            setOpenPage('Warrant Status')
                        }} data-target="#ListModel" className='new-link'>
                            Warrant&nbsp;Status
                        </span>
                    </div>

                    <div className="col-3 col-md-3 col-lg-2  mt-1">
                        <Select
                            name='WarrantStatusID'
                            styles={customStylesWithOutColor}
                            value={warrantStatus?.filter((obj) => obj.value === value?.WarrantStatusID)}
                            isClearable
                            options={warrantStatus}
                            onChange={(e) => ChangeDropDown(e, 'WarrantStatusID')}
                            placeholder="Select..."
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        <label htmlFor="" className='label-name '>Issued Date/Time{errors.DateTimeIssuedErrors !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DateTimeIssuedErrors}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 ">
                        {/* <DatePicker
                            id="DateTimeIssued"
                            name="DateTimeIssued"
                            dateFormat="MM/dd/yyyy HH:mm"
                            showTimeSelect
                            timeCaption="Time"
                            className='requiredColor'
                            // onChange={(date) => { setDateTimeIssued(date); setStatesChangeStatus(true); setValue({ ...value, ["DateTimeIssued"]: date ? getShowingDateText(date) : null, }); }}
                            onChange={(date) => {
                                setChangesStatus(true); setStatesChangeStatus(true);
                                if (date >= new Date()) {
                                    setValue({ ...value, ['DateTimeIssued']: new Date() ? getShowingDateText(new Date()) : null })
                                } else if (date <= new Date(incReportedDate)) {
                                    setValue({ ...value, ['DateTimeIssued']: incReportedDate ? getShowingDateText(incReportedDate) : null })
                                } else {
                                    setValue({ ...value, ['DateTimeIssued']: date ? getShowingDateText(date) : null })
                                }
                            }}
                            isClearable={value?.DateTimeIssued ? true : false}
                            // selected={DateTimeIssued}
                            selected={value?.DateTimeIssued && new Date(value?.DateTimeIssued)}
                            minDate={new Date(incReportedDate)}
                            maxDate={new Date()}
                            placeholderText={"Select..."}
                            autoComplete="Off"
                            dropdownMode="select"
                            showMonthDropdown
                            showDisabledMonthNavigation
                            showYearDropdown
                        /> */}
                        {/* <DatePicker
                            // id='DateTimeIssued'
                            // name='DateTimeIssued'
                            // ref={startRef1}
                            // // onKeyDown={onKeyDown}
                            // onKeyDown={(e) => {
                            //     if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                            //         e?.preventDefault();
                            //     } else {
                            //         onKeyDown(e);
                            //     }
                            // }}
                            // onChange={(date) => {
                            //     if (date) {
                            //         if (date >= new Date()) {
                            //             setChangesStatus(true); setStatesChangeStatus(true); setDateTimeIssued(new Date()); setValue({ ...value, ['DateTimeIssued']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                            //         } else if (date <= new Date(incReportedDate)) {
                            //             setChangesStatus(true); setStatesChangeStatus(true); setDateTimeIssued(incReportedDate); setValue({ ...value, ['DateTimeIssued']: incReportedDate ? getShowingMonthDateYear(incReportedDate) : null })
                            //         } else {
                            //             setChangesStatus(true); setStatesChangeStatus(true); setDateTimeIssued(date); setValue({ ...value, ['DateTimeIssued']: date ? getShowingMonthDateYear(date) : null })
                            //         }
                            //     } else {
                            //         setChangesStatus(true); setStatesChangeStatus(true); setDateTimeIssued(null); setValue({ ...value, ['DateTimeIssued']: null })
                            //     }

                            // }}
                            // className='requiredColor'
                            // dateFormat="MM/dd/yyyy HH:mm"
                            // timeInputLabel
                            // showYearDropdown
                            // showMonthDropdown
                            // dropdownMode="select"
                            // isClearable={value?.DateTimeIssued ? true : false}
                            // selected={value?.DateTimeIssued ? new Date(value?.DateTimeIssued) : null}
                            // placeholderText={value?.DateTimeIssued ? value.DateTimeIssued : 'Select...'}
                            // showTimeSelect
                            // timeIntervals={1}
                            // timeCaption="Time"
                            // autoComplete="Off"
                            // // filterTime={filterPassedTime}
                            // filterTime={(time) => filterPassedDateTime(time, value?.DateTimeIssued, incReportedDate)}
                            // maxDate={new Date()}
                            // minDate={new Date(incReportedDate)}
                            ref={startRef1}
                            onKeyDown={(e) => {
                                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                    e.preventDefault();
                                } else {
                                    onKeyDown(e);
                                }
                            }
                            }
                            id='DateTimeIssued'
                            name='DateTimeIssued'
                            className='requiredColor'
                            dateFormat="MM/dd/yyyy HH:mm"
                            onChange={(date) => {
                                if (date) {
                                    setStatesChangeStatus(true); setChangesStatus(true)
                                    if (date >= new Date()) {
                                        setValue({ ...value, ['DateTimeIssued']: new Date() ? getShowingDateText(new Date()) : null })
                                        setDateTimeIssued(new Date());
                                    } else if (date <= new Date(incReportedDate)) {
                                        setValue({ ...value, ['DateTimeIssued']: incReportedDate ? getShowingDateText(incReportedDate) : null })
                                        setDateTimeIssued(incReportedDate);
                                    } else {
                                        setDateTimeIssued(date);
                                        setValue({ ...value, ['DateTimeIssued']: date ? getShowingMonthDateYear(date) : null })
                                    }
                                } else {
                                    setDateTimeIssued(null);
                                    setValue({ ...value, ['DateTimeIssued']: null })
                                    setStatesChangeStatus(true);
                                }
                            }}
                            placeholderText={value?.DateTimeIssued ? value?.DateTimeIssued : 'Select...'}
                            // selected={activityDate}
                            selected={value?.DateTimeIssued ? new Date(value?.DateTimeIssued) : null}
                            isClearable={value?.DateTimeIssued ? true : false}
                            timeInputLabel
                            showTimeSelect
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            minDate={new Date(1990, 0, 1)}
                            // minDate={new Date(incReportedDate)}
                            maxDate={new Date()}
                            showDisabledMonthNavigation
                            filterTime={(time) => filterPassedDateTime(time, DateTimeIssued, incReportedDate)}
                            // filterTime={(time) => filterPassedDateTime(time, value?.DateTimeIssued, incReportedDate)}
                            autoComplete='off'
                            timeFormat="HH:mm"
                            is24Hour
                        /> */}
                        <DatePicker
                            id='DateTimeIssued'
                            name='DateTimeIssued'
                            ref={startRef1}
                            className='requiredColor'
                            dateFormat="MM/dd/yyyy HH:mm"
                            selected={value?.DateTimeIssued ? new Date(value?.DateTimeIssued) : null}
                            isClearable={Boolean(value?.DateTimeIssued)}
                            placeholderText={value?.DateTimeIssued || 'Select...'}
                            showTimeSelect
                            timeInputLabel="Time"
                            timeIntervals={1}
                            timeCaption="Time"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            minDate={new Date(NameDateExpired)}
                            // minDate={new Date(1990, 0, 1)}
                            maxDate={new Date()}
                            // filterTime={(time) => filterPassedDateTime(time, value?.DateTimeIssued, incReportedDate)}
                            autoComplete='off'
                            timeFormat="HH:mm"
                            is24Hour
                            onKeyDown={(e) => {
                                if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ' || e.key === 'F5')) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(date) => {
                                setStatesChangeStatus(true);
                                setChangesStatus(true);
                                if (date) {
                                    setDateTimeIssued(date);
                                    setValue({ ...value, ['DateTimeIssued']: date ? getShowingMonthDateYear(date) : null });
                                } else {
                                    setDateTimeIssued(null);
                                    setValue({ ...value, ['DateTimeIssued']: null });
                                }
                            }}
                            filterTime={filterPassedTime}
                        />

                    </div>
                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                        <label htmlFor="" className='label-name '>Expired Date/Time{errors.DateExpiredErrors !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DateExpiredErrors}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2 ">
                        <DatePicker
                            id="DateExpired"
                            name="DateExpired"
                            onChange={(date) => { setDateExpired(date); setStatesChangeStatus(true); setValue({ ...value, ["DateExpired"]: date ? getShowingDateText(date) : null, }); }}
                            // isClearable
                            isClearable={value?.DateExpired ? true : false}
                            selected={DateExpired}
                            placeholderText={"Select..."}
                            autoComplete="Off"
                            dropdownMode="select"
                            showMonthDropdown
                            showDisabledMonthNavigation
                            showYearDropdown
                            dateFormat="MM/dd/yyyy HH:mm"
                            timeFormat="HH:mm "
                            is24Hour
                            showTimeSelect
                            className='requiredColor'
                            timeCaption="Time"
                            // minDate={value?.DateTimeIssued ? new Date(value?.DateTimeIssued) : null}
                            // ={!value?.DateTimeIssued}
                            isDisabled={!value?.DateTimeIssued}
                            // filterTime={(time) => filterPassedDateTime(time, value?.value?.DateExpired, value?.DateTimeIssued)}
                            timeIntervals={1}
                            // minDate={new Date(NameDateExpired)}
                            minDate={ListData[0]?.DateOfBirth ? new Date(NameDateExpired) : (value?.DateTimeIssued ? new Date(value?.DateTimeIssued) : null)}

                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-1 mt-2">
                        <label htmlFor="" className='label-name '>Issuing&nbsp;Agency{errors.IssuingAgencyIDErrors !== 'true' ? (
                            <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IssuingAgencyIDErrors}</p>
                        ) : null}</label>
                    </div>
                    <div className="col-9 col-md-9 col-lg-11 mt-1" >
                        <Select
                            name='IssuingAgencyID'
                            styles={colourStyles}
                            value={agencyData?.filter((obj) => obj.value === value?.IssuingAgencyID)}
                            isClearable
                            options={agencyData}
                            onChange={(e) => ChangeDropDown(e, 'IssuingAgencyID')}
                            placeholder="Select..."
                        />

                    </div>

                </div>

                <div className="btn-box text-right mr-1 mb-2 mt-3">
                    <button type="button" data-dismiss="modal" onClick={() => {
                        setStatusFalse();
                    }} className="btn btn-sm btn-success mr-1" >New</button>

                    {
                        status ?
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.Changeok ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                    :
                                    <>
                                    </>
                                :
                                <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }}>Update</button>
                            :
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.AddOK ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                    :
                                    <>
                                    </>
                                :
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                    }

                </div>
            </div >
            <div className="col-12 mt-3">
                <DataTable
                    dense
                    columns={columns}
                    // data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? warrantDetailsData : [] : warrantDetailsData}
                    data={warrentTypeData}
                    pagination
                    highlightOnHover
                    customStyles={tableCustomStyles}
                    onRowClicked={(row) => {
                        setClickedRow(row);
                        set_Edit_Value(row);
                    }}
                    fixedHeader
                    persistTableHead={true}
                    fixedHeaderScrollHeight='200px'
                    conditionalRowStyles={conditionalRowStyles}
                    noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                />
            </div>
            <DeletePopUpModal func={DeleteNameAliases} />
            <ChangesModal func={check_Validation_Error} />
            <ListModal {...{ openPage, setOpenPage }} />

        </>
    )
}

export default Warrant