import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingWithOutTime } from '../../../Common/Utility';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { AgencyContext } from '../../../../Context/Agency/Index';
import { fetchPostData } from '../../../hooks/Api';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { toastifyError } from '../../../Common/AlertMsg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_AgencyOfficer_Data, get_ArrestJuvenileDis_DrpData, get_ArrestType_Drp, get_Arresting_DropDown, get_NIBRS_Drp_Data } from '../../../../redux/actions/DropDownsData';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { number } from 'prop-types';

const ArrestSearchPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const arrestTypeDrpData = useSelector((state) => state.DropDown.arrestTypeDrpData);
    const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);
    const arrestJuvenileDisDrpData = useSelector((state) => state.DropDown.arrestJuvenileDisDrpData);
    const arrestingDrpData = useSelector((state) => state.DropDown.arrestingDrpData);
    const NIBRSDrpData = useSelector((state) => state.DropDown.NIBRSDrpData);

    const { get_Police_Force, setArrestSearchData } = useContext(AgencyContext)
    const [arrestfromDate, setArrestfromDate] = useState();
    const [arresttoDate, setArresttoDate] = useState();
    const [chargeCodeDrp, setChargeCodeDrp] = useState([]);
    const [loginAgencyID, setloginAgencyID] = useState('');
    const [loginPinID, setloginPinID,] = useState('');

    const [value, setValue] = useState({
        'ArrestNumber': '', 'ArrestNumberTo': '', 'IncidentNumber': '', 'DLNumber': '', 'ArrestDtTm': '', 'ArrestDtTmTo': '',
        'ArrestTypeID': null, 'ArrestingAgency': '', 'JuvenileDispositionID': null, 'LastName': '', 'AgencyID': '',
        'FirstName': '', 'MiddleName': '', 'SSN': '', 'PrimaryOfficerID': null, 'ChargeCodeID': null,
        'NIBRSID': null, 'Location': '', 'FBI': '', 'SBI': ''
    });

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(parseInt(localStoreData?.AgencyID)); setloginPinID(parseInt(localStoreData?.PINID));
        }
    }, [localStoreData]);

    useEffect(() => {
        if (loginAgencyID) {
            get_ChargeCode_Drp_Data(loginAgencyID);
            if (NIBRSDrpData?.length === 0) { dispatch(get_NIBRS_Drp_Data(loginAgencyID)); }
            if (arrestTypeDrpData?.length === 0) dispatch(get_ArrestType_Drp(loginAgencyID));
            if (agencyOfficerDrpData?.length === 0) { dispatch(get_AgencyOfficer_Data(loginAgencyID)) }
            if (arrestJuvenileDisDrpData?.length === 0) dispatch(get_ArrestJuvenileDis_DrpData(loginAgencyID));
            if (arrestingDrpData?.length === 0) dispatch(get_Arresting_DropDown(loginAgencyID));
        }
        get_Police_Force();
    }, [loginAgencyID])

    // / Arrest Data
    // const get_Data_Arrest = () => {
    //     if (value?.ArrestNumber?.trim() || value?.ArrestNumberTo?.trim() || value?.IncidentNumber?.trim() ||
    //         value?.ArrestDtTm?.trim() || value?.ArrestDtTmTo?.trim() || value?.ArrestTypeID || value?.ArrestingAgency?.trim() ||
    //         value?.JuvenileDispositionID || value?.LastName?.trim() || value?.FirstName?.trim() || value?.MiddleName?.trim() ||
    //         value?.DLNumber?.trim() || value?.SSN?.trim() || value?.PrimaryOfficerID || value?.ChargeCodeID || value?.NIBRSID ||
    //         value?.FBI || value?.SBI || value?.FBI || value?.Location
    //     );
    //     const p = {
    //         ArrestNumber, ArrestNumberTo, IncidentNumber, ArrestDtTm, ArrestDtTmTo, ArrestTypeID, ArrestingAgency, JuvenileDispositionID, LastName, FirstName, MiddleName, SSN, DLNumber, PrimaryOfficerID, ChargeCodeID, NIBRSID, Location, FBI, SBI,
    //     };
    //     fetchPostData('Arrest/Search_Arrest', val).then((res) => {
    //         if (res.length > 0) {
    //             console.log(res);
    //             setArrestSearchData(res); reset(); navigate('/arrest-search');
    //         } else {
    //             toastifyError("Data Not Available"); setArrestSearchData([]);
    //         }
    //     })
    // }

    const get_Data_Arrest = async () => {
        if (value?.ArrestNumber?.trim() || value?.ArrestNumberTo?.trim() || value?.IncidentNumber?.trim() ||
            value?.ArrestDtTm?.trim() || value?.ArrestDtTmTo?.trim() || value?.ArrestTypeID || value?.ArrestingAgency?.trim() ||
            value?.JuvenileDispositionID || value?.LastName?.trim() || value?.FirstName?.trim() || value?.MiddleName?.trim() ||
            value?.DLNumber?.trim() || value?.SSN?.trim() || value?.PrimaryOfficerID || value?.ChargeCodeID || value?.NIBRSID ||
            value?.FBI || value?.SBI || value?.FBI || value?.Location
        ) {
            const {
                AgencyID, ArrestNumber, ArrestNumberTo, IncidentNumber, ArrestDtTm, ArrestDtTmTo, ArrestTypeID, ArrestingAgency, JuvenileDispositionID, LastName, FirstName, MiddleName, SSN, DLNumber, PrimaryOfficerID, ChargeCodeID, NIBRSID, Location, FBI, SBI,
            } = value;
            const payload = {
                AgencyID: loginAgencyID, ArrestNumber, ArrestNumberTo, IncidentNumber, ArrestDtTm, ArrestDtTmTo, ArrestTypeID, ArrestingAgency, JuvenileDispositionID, LastName, FirstName, MiddleName, SSN, DLNumber, PrimaryOfficerID, ChargeCodeID, NIBRSID, Location, FBI, SBI,
            };
            const res = await fetchPostData("Arrest/Search_Arrest", payload);
            if (res.length > 0) {
                setArrestSearchData(res); reset(); navigate('/arrest-search');
            } else {
                toastifyError("Data Not Available"); setArrestSearchData([]); reset();
            }

        } else {
            toastifyError("Please Enter Details");
        }
    };

    const get_ChargeCode_Drp_Data = (FBIID) => {
        const val = {
            // 'AgencyID': loginAgencyID,
            'FBIID': FBIID
        }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setChargeCodeDrp(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setChargeCodeDrp([]);
            }
        })
    }

    const reset = () => {
        setValue({
            ...value,
            'ArrestNumber': null, 'ArrestNumberTo': null, 'IncidentNumber': null, 'ArrestDtTm': null, 'ArrestDtTmTo': null,
            'ArrestTypeID': null, 'ArrestingAgency': null, 'DLNumber': null, 'JuvenileDispositionID': null, 'LastName': null,
            'FirstName': null, 'MiddleName': null, 'SSN': null, 'PrimaryOfficerID': null, 'ChargeCodeID': null,
            'NIBRSID': null, 'Location': null, 'FBI': null, 'SBI': null,
        });
        setArresttoDate(null); setArrestfromDate(null);
    }

    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         if (e.target.name === 'ArrestNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele[0]?.match(/[a-zA-Z\s]/g)) {
    //                 if (ele?.length <= 4) {
    //                     const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: alphabet
    //                     });
    //                 } else if (ele.length >= 15) {
    //                     e?.preventDefault()
    //                 }
    //                 else {
    //                     let subs = ele.substring(0, 4);
    //                     let subs2 = ele.substring(4).replace(/[^0-9]/g, '');
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: subs + '-' + subs2
    //                     })
    //                 }
    //             } else {
    //                 if (ele.length === 8) {
    //                     var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
    //                     var match = cleaned.match(/^(\d{2})(\d{6})$/);
    //                     if (match) {
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: match[1] + '-' + match[2]
    //                         })
    //                     }
    //                 } else if (ele.length >= 8) {
    //                     e?.preventDefault()
    //                 } else {
    //                     ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: ele
    //                     })
    //                 }
    //             }
    //         } else {
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: e.target.value
    //             })
    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }

    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele[0]?.match(/[a-zA-Z\s]/g)) {
    //                 let subs = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '').substring(0, 4);
    //                 let subs2 = ele.replace(/[^0-9]/g, '');
    //                 if (ele?.length <= 4) {
    //                     const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: alphabet
    //                     })
    //                 } else if (ele.length >= 15) {
    //                     e?.preventDefault()
    //                 } else if (subs2?.length >= 11) {
    //                     // console.log(subs2, 'asdasdas')
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: subs + '-' + subs2
    //                     })
    //                 }
    //             } else {
    //                 let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //                 if (ele[0]?.match(/[0-9]/g)) {
    //                     const digits = ele.replace(/[^0-9]/g, '');
    //                     if (ele?.length <= 2) {
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: digits
    //                         })
    //                     } else if (ele.length >= 9) {
    //                         e.preventDefault();
    //                     } else {
    //                         let subs = ele.substring(0, 2);
    //                         let subs2 = ele.substring(2).replace(/[^0-9]/g, '');
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: subs + '-' + subs2
    //                         })
    //                     }
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: e.target.value
    //                     })
    //                 }

    //             }
    //         }
    //     }
    // }

    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         const { name, value } = e.target;
    //         if (name === 'ArrestNumber' || name === 'ArrestNumberTo') {
    //             let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    //             const alphaPart = sanitizedValue.substring(0, 4).toUpperCase();
    //             const numericPart = sanitizedValue.substring(4);
    //             let formattedValue = alphaPart;
    //             if (numericPart.length > 0) {
    //                 if (numericPart.length <= 2) {
    //                     formattedValue += '-' + numericPart;
    //                 } else if (numericPart.length <= 8) {
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const sixDigitPart = numericPart.substring(2, 8);
    //                     formattedValue += '-' + twoDigitPart;
    //                     if (sixDigitPart.length > 0) {
    //                         formattedValue += '-' + sixDigitPart;
    //                     }
    //                 } else {
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const eightDigitPart = numericPart.substring(2, 10);
    //                     formattedValue += '-' + twoDigitPart + eightDigitPart;
    //                 }
    //             }
    //             setValue({
    //                 ...value,
    //                 [name]: formattedValue
    //             });
    //         }
    //     }
    // }

    const HandleChange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        }
        else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        // else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        //     if (ele.length === 8) {
        //         const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
        //         const match = cleaned.match(/^(\d{2})(\d{6})$/);
        //         if (match) {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: match[1] + '-' + match[2]
        //             })
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         })
        //         if (ele?.length == 0) { e.target.name == 'ArrestNumber' && setValue({ ...value, ['ArrestNumberTo']: "", [e.target.name]: ele }) }
        //     }
        // }

        // else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

        //     if (ele.length >= 14) {
        //         const cleaned = ele.slice(0, 14);
        //         const match = cleaned.match(/^(\w{4})(\w{2})(\w{8})$/);
        //         if (match) {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
        //             });
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         });

        //         if (ele.length === 0) {
        //             e.target.name === 'ArrestNumber' && setValue({
        //                 ...value,
        //                 ['ArrestNumberTo']: "",
        //                 [e.target.name]: ele
        //             });
        //         }
        //     }
        // }
        else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
            let ele = e.target.value.trim();
            setValue({
                ...value,
                [e.target.name]: ele
            });
            if (ele.length === 0) {
                e.target.name === 'ArrestNumber' && setValue({
                    ...value,
                    ['ArrestNumberTo']: "",
                    [e.target.name]: ele
                });
            }
        }

        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    // const ChangeDropDown = (e, name) => {
    //     if (e) {
    //         setValue({
    //             ...value,
    //             [name]: e.value,
    //         })
    //     } else {
    //         setValue({
    //             ...value,
    //             [name]: null,
    //         })
    //     }
    // }
    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'NIBRSID') {
                get_ChargeCode_Drp_Data(e.value);
                setValue({ ...value, [name]: e.value, ['ChargeCodeID']: '', });

            } else {
                setValue({ ...value, [name]: e.value, });

            }
        } else if (e === null) {
            if (name === 'NIBRSID') {
                get_ChargeCode_Drp_Data([]);
                setValue({ ...value, ['NIBRSID']: "", ['ChargeCodeID']: "", });

            } else {
                setValue({ ...value, [name]: null });

            }
        } else {
            setValue({ ...value, [name]: null })

        }
    }
    const onClose = () => {
        navigate('/dashboard-page'); reset();
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
        }
    };

    return (
        <div className=" section-body pt-3 p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row ">
                                    <div className="col-12 ">
                                        <fieldset >
                                            <legend>Arrest Information</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Incident Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='IncidentNumber' name='IncidentNumber' maxLength={9} value={value?.IncidentNumber} onChange={HandleChange} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Arrest Number From</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Arrest Number To</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field mt-1">
                                                    <input type="text" id='ArrestNumberTo' disabled={!value?.ArrestNumber?.trim()}
                                                        className={!value?.ArrestNumber?.trim() ? 'readonlyColor' : ''} name='ArrestNumberTo' value={value?.ArrestNumberTo} onChange={HandleChange} />
                                                    {/* <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo} onChange={''} /> */}
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Arrest From Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3  ">
                                                    <DatePicker
                                                        id='ArrestDtTm'
                                                        name='ArrestDtTm'
                                                        ref={startRef}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setArrestfromDate(date); setValue({ ...value, ['ArrestDtTm']: date ? getShowingWithOutTime(date) : null, ['ArrestDtTmTo']: date ? value.ArrestDtTmTo : null }) }}
                                                        className=''
                                                        dateFormat="MM/dd/yyyy"
                                                        autoComplete='Off'
                                                        timeInputLabel
                                                        maxDate={new Date()}
                                                        isClearable
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="select"
                                                        selected={arrestfromDate}
                                                        placeholderText={value?.ArrestDtTm ? value.ArrestDtTm : 'Select...'}
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Arrest To Date</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 ">
                                                    <DatePicker
                                                        id='ArrestDtTmTo'
                                                        name='ArrestDtTmTo'
                                                        ref={startRef1}
                                                        onKeyDown={onKeyDown}
                                                        onChange={(date) => { setArresttoDate(date); setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingWithOutTime(date) : null }) }}
                                                        dateFormat="MM/dd/yyyy"
                                                        autoComplete='Off'
                                                        timeInputLabel
                                                        showMonthDropdown
                                                        minDate={arrestfromDate}
                                                        maxDate={new Date()}
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        // selected={arresttoDate}
                                                        placeholderText={value?.ArrestDtTmTo ? value.ArrestDtTmTo : 'Select...'}
                                                        // disabled={value?.ArrestDtTm ? false : true}
                                                        isClearable={value?.ArrestDtTmTo ? true : false}
                                                        selected={value?.ArrestDtTmTo && new Date(value?.ArrestDtTmTo)}
                                                        disabled={!value?.ArrestDtTm}
                                                        className={!value?.ArrestDtTm ? 'readonlyColor' : ''}
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Arrest Type</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3  ">
                                                    <Select
                                                        name='ArrestTypeID'
                                                        styles={colourStyles}
                                                        value={arrestTypeDrpData?.filter((obj) => obj.value === value?.ArrestTypeID)}
                                                        isClearable
                                                        options={arrestTypeDrpData}
                                                        onChange={(e) => { ChangeDropDown(e, 'ArrestTypeID') }}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Arresting Officer</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 ">
                                                    <Select
                                                        name='PrimaryOfficerID'
                                                        styles={colourStyles}
                                                        menuPlacement='bottom'
                                                        value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                                        isClearable
                                                        options={agencyOfficerDrpData}
                                                        onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'> NIBRS Code/Name</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-3 mt-1">
                                                    <Select
                                                        styles={colourStyles}
                                                        name="NIBRSID"
                                                        value={NIBRSDrpData?.filter((obj) => obj.value === value?.NIBRSID)}
                                                        isClearable
                                                        options={NIBRSDrpData}
                                                        onChange={(e) => { ChangeDropDown(e, 'NIBRSID') }}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-3  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Charge Code/Description</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-3 mt-1">
                                                    <Select
                                                        name='ChargeCodeID'
                                                        styles={colourStyles}
                                                        value={chargeCodeDrp?.filter((obj) => obj.value === value?.ChargeCodeID)}
                                                        isClearable
                                                        options={chargeCodeDrp}
                                                        onChange={(e) => ChangeDropDown(e, 'ChargeCodeID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>

                                                <div className="col-2 col-md-2 col-lg-2  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Arresting Agency</label>
                                                </div>
                                                {/* <div className="col-4 col-md-4 col-lg-3">
                                                    <Select
                                                        name="ArrestingAgency"
                                                        styles={colourStyles}
                                                        value={arrestingDrpData?.filter((obj) => obj.value === value?.ArrestingAgency)}
                                                        isClearable
                                                        options={arrestingDrpData}
                                                        onChange={(e) => { ChangeDropDown(e, 'ArrestingAgency') }}
                                                        placeholder="Select..."
                                                    />
                                                </div> */}
                                                <div className="col-4 col-md-4 col-lg-3 mt-1  text-field">
                                                    <input type="text" name='ArrestingAgency' id='ArrestingAgency' value={value?.ArrestingAgency} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-3  mt-2 ">
                                                    <label htmlFor="" className='new-label'>Juvenile Disposition</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 ">
                                                    <Select
                                                        name='JuvenileDispositionID'
                                                        menuPlacement='bottom'
                                                        styles={colourStyles}
                                                        value={arrestJuvenileDisDrpData?.filter((obj) => obj.value === value?.JuvenileDispositionID)}
                                                        isClearable
                                                        options={arrestJuvenileDisDrpData}
                                                        onChange={(e) => ChangeDropDown(e, 'JuvenileDispositionID')}
                                                        placeholder="Select..."
                                                    />
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                                    <label htmlFor="" className='new-label'>Location</label>
                                                </div>
                                                <div className="col-9 col-md-9 col-lg-9 mt-1 text-field">
                                                    <input type="text" name='Location' value={value?.Location} onChange={HandleChange} id='Location' className='' />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Arrestee Information</legend>
                                            <div className="row">
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='LastName' name='LastName' value={value?.LastName} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='FirstName' name='FirstName' value={value?.FirstName} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2 px-0">
                                                    <label htmlFor="" className='new-label px-0'>Middle Name</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='MiddleName' name='MiddleName' value={value?.MiddleName} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>SSN</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='SSN' name='SSN' maxLength={9} value={value?.SSN} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>DL Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    {/* <input type="text"
                                                        id='DLNumber'
                                                        name='DLNumber'
                                                        style={{ textTransform: "uppercase" }}
                                                        //   value={value?.DLNumber}
                                                        value={value?.DLNumber ? value.DLNumber.replace(/[^\w\s]/g, '') : ''}
                                                        maxLength={21}
                                                        onChange={HandleChange} /> */}
                                                    <input type="text" id='DLNumber' name='DLNumber' maxLength={21} value={value?.DLNumber} onChange={HandleChange} />
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>FBI Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='FBI' name='FBI' maxLength={25} value={value?.FBI} onChange={HandleChange} />

                                                </div>
                                                <div className="col-2 col-md-2 col-lg-1  mt-2 pt-2">
                                                    <label htmlFor="" className='new-label'>SBI Number</label>
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-3 text-field">
                                                    <input type="text" id='SBI' name='SBI' maxLength={25} value={value?.SBI} onChange={HandleChange} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { get_Data_Arrest(); }}>Search</button>
                                <button type="button" onClick={() => onClose()} className="btn btn-sm btn-success mr-1" >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ArrestSearchPage