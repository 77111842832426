import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { getShowingWithOutTime, tableCustomStyles } from '../../../../Common/Utility'
import { fetchPostData } from '../../../../hooks/Api'

const VehicleChain = (props) => {
    const { DecVehId, DecMPropID, } = props

    const [data, setData] = useState([]);

    const get_ChainOfCustodyData = () => {
        const val = {
            'PropertyID': DecVehId, 'MasterpropertyID': 0,
        };
        fetchPostData('Propertyroom/GetData_ChainOfCustody', val).then((res) => {
            if (res) {
                setData(res);
            } else { setData([]); }
        });
    };



    useEffect(() => {
        if (DecVehId) { get_ChainOfCustodyData(DecVehId); }
    }, []);

    const columns = [
        {
            name: 'Officer Name',
            selector: (row) => row.Officer_Name,
            sortable: true,
        },
        {
            name: 'ActivityReason',
            selector: (row) => row.ActivityReason_Des,
            sortable: true,
        },
        {
            name: 'Other Person Name',
            selector: (row) => row.OtherPersonName_Name,
            sortable: true,
        },
        {
            name: 'Activity Type',
            selector: (row) => row.Status,
            sortable: true,
        },
    ];

    // const columns = [
    //     {
    //         name: 'Officer Name',
    //         selector: (row) => row.OfficerName,
    //         sortable: true

    //     },

    //     {
    //         name: 'Property Number',
    //         selector: (row) => row.PropertyNumber,
    //         sortable: true
    //     },
    //     {
    //         name: 'Activity',
    //         selector: (row) => row.Activity,
    //         sortable: true
    //     },
    //     {
    //         name: 'Date/Time',
    //         selector: (row) => row.date ? getShowingWithOutTime(row.date) : " ",
    //         sortable: true

    //     },

    //     {
    //         name: 'Property Room',
    //         selector: (row) => row.PropertyRoom,
    //         sortable: true

    //     },
    //     {
    //         name: 'Location',
    //         selector: (row) => row.Location,
    //         sortable: true

    //     },
    //     {
    //         name: 'Schedule Destroy Date',
    //         selector: (row) => row.ScheduleDate ? getShowingWithOutTime(row.ScheduleDate) : " ",
    //         sortable: true

    //     },
    //     {
    //         name: 'Schedule Court Date',
    //         selector: (row) => row.ScheduleCourtDate ? getShowingWithOutTime(row.ScheduleCourtDate) : " ",
    //         sortable: true

    //     },
    //     {
    //         name: 'Schedule Release Date',
    //         selector: (row) => row.ScheduleReleaseDate ? getShowingWithOutTime(row.ScheduleReleaseDate) : " ",
    //         sortable: true

    //     },
    //     {
    //         name: 'Release To',
    //         selector: (row) => row.ReleaseTo,
    //         sortable: true

    //     },
    //     {
    //         name: 'Comments',
    //         selector: (row) => row.Comments,
    //         sortable: true

    //     },
    // ]

    return (
        <div className="col-12 px-3 mt-2" >
            <DataTable
                columns={columns}
                data={data}
                showHeader={true}
                persistTableHead={true}
                dense
                highlightOnHover
                responsive
                customStyles={tableCustomStyles}
                fixedHeader
                fixedHeaderScrollHeight='220px'
                pagination
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 150, 200, 500]}
                paginationComponentOptions={{ rowsPerPageText: 'Rows per page:' }}
                showPaginationBottom={100}

            />
        </div>
    )
}

export default VehicleChain